import React from "react";

export const DragDropIcon = () => {
  return (
    <span
      className="flex h-6 w-3 items-center hover:bg-slate-200 rounded mt-0"
      aria-hidden="true"
    >
      <svg
        className="fill-gray-400 hover:fill-gray-800 hover:cursor-pointer"
        viewBox="0 0 20 20"
        width="12"
      >
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </span>
  );
};
