import React, { useEffect, useState } from "react";
import axiosJobs from "../../../../../../services/axios/jobs";
import LoadingIcon from "../../../../../../components/Loaders/LoadingIcon";
import SubtleButton from "../../../../../../components/Buttons/SubtleButton";
import MapJobCard from "../../MapJobCard";
import Sidepanel from "../../../../../../components/Maps/ActiveRoutes/ui/sidepanel";
import SidepanelHeader from "../../../../../../components/Maps/ActiveRoutes/ui/sidepanel-header";
import SidepanelFooter from "../../SidepanelFooter";

const AddProperties = (props) => {
  // const [currentFilter, setCurrentFilter] = useState('all');
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  const existingJobs = props.existingJobs;

  useEffect(() => {
    setJobsLoading(true);
    axiosJobs.getAllJobsForSeason(
      { companyId: props.companyId, season: props.season },
      (data) => {
        setJobs(data);
        setJobsLoading(false);
      },
    );
  }, [props.companyId, props.season]);

  const handleAddJobs = () => {
    const jobsToAdd = selectedJobIds.map((j) =>
      jobs.find((element) => element._id === j),
    );

    props.concatJobs(jobsToAdd);
  };

  const handleCardPressed = (index) => {
    let updatedJobs = [...jobs];
    const updatedJob = { ...updatedJobs[index] };
    let updatedSelectedJobIds = [...selectedJobIds];

    // If job not already selected, select
    if (!updatedJob.cardSequence) {
      updatedJobs[index].cardSequence = selectedJobIds.length + 1;
      updatedSelectedJobIds.push(updatedJob._id);
    } else {
      updatedSelectedJobIds = updatedSelectedJobIds.filter(
        (id) => id !== updatedJob._id,
      );
      updatedJobs = updatedJobs.map((job, i) => {
        if (index === i) {
          // selected job
          return { ...job, cardSequence: null };
        } else if (job.cardSequence > updatedJob.cardSequence) {
          // greater than selected job
          return { ...job, cardSequence: job.cardSequence - 1 };
        } else {
          // less than selected job
          return { ...job };
        }
      });
    }

    // Update State
    setJobs(updatedJobs);
    setSelectedJobIds(updatedSelectedJobIds);
  };

  return (
    <Sidepanel
      panelStyle={{ top: "3rem", right: "3rem", zIndex: 20 }}
      style={{ width: 300, height: 600, background: "#f6f6f6" }}
    >
      <div className="h-full grid grid-rows-[60px_auto_77px]">
        <div className="px-5 py-4 border-b border-gray-200">
          <div className="flex items-start justify-between">
            <SidepanelHeader>Add Properties</SidepanelHeader>
          </div>
        </div>

        {/* <FilterBar
                currentFilter={currentFilter}
                filters={[
                    { label: 'All', value: 'all' },
                    { label: 'Landscape', value: 'landscape' },
                    { label: 'Snow', value: 'snow' },
                ]}
                lightMode={true}
                setCurrentFilter={setCurrentFilter}
            /> */}

        <div className="p-4 py-1 overflow-y-scroll">
          <p className="text-sm my-2">
            If you want to service a property more than once, select it again
            below.
          </p>
          {jobsLoading ? (
            <LoadingIcon color="white" />
          ) : (
            <ul role="list" className="divide-y divide-gray-100">
              {jobs.map((job, key) => {
                const existing =
                  existingJobs.find((ej) => ej._id == job._id) !== undefined
                    ? "opacity-70"
                    : "";
                const className = `py-2 hover:opacity-80 active:opacity-40 ${existing} cursor-pointer`;
                return (
                  <li
                    key={key}
                    className={className}
                    onClick={() => {
                      handleCardPressed(key);
                    }}
                    data-testid={props.testId ?? `job-card-${props.name ?? ""}`}
                  >
                    <MapJobCard
                      address={job.address}
                      cardSequence={job.cardSequence}
                      key={key}
                      name={job.name}
                      routes={job.routes ?? []}
                      previouslySelected={existing}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <SidepanelFooter>
          <SubtleButton
            onClick={handleAddJobs}
            type="submit"
            testId="complete-add-properties-button"
            buttonRole="primary"
            className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
          >
            Add
          </SubtleButton>
          <SubtleButton
            onClick={() => (props.onCancel ? props.onCancel() : null)}
            type="submit"
            testId="cancel-add-button"
            buttonRole="secondary"
            className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
          >
            Cancel
          </SubtleButton>
        </SidepanelFooter>
      </div>
    </Sidepanel>
  );
};

export default AddProperties;
