import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import SplitForm from "../../components/SplitForm";
import { useState } from "react";
import BasicInfoForm from "./BasicInfoForm";
import ValidateCodeForm from "./ValidateCodeForm";
import axiosUsers from "../../services/axios/users";
import { connect, useDispatch } from "react-redux";
import { updateUser } from "../../store/reducers/currentUserSlice";
import axios from "axios";
import { AddOnPricing, PricingData } from "../../components/Pricing";
import { useMutation } from "@tanstack/react-query";
import errors from "../../services/errors";
import { useAuthState } from "react-firebase-hooks/auth";

const SignUpPage = ({ currentUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firebaseUser, authLoading] = useAuthState(firebaseAuth.auth);

  // Get the desired plan from the url if provided:
  const [searchParams] = useSearchParams();
  let selectedPlan = searchParams.get("plan");

  const [sentCode, setSentCode] = useState(false); // have we sent the customer a SMS
  const [formData, setFormData] = useState(null); // The result of the BasicInfo form
  const [formError, setFormError] = useState(null);

  const signUpForPlanWithSubscription = useMutation({
    mutationFn: ({
      plan,
      extra_users,
      extra_companies,
      email,
      companyName,
    }) => {
      return axios.post("/stripe/create-customer-with-subscription", {
        plan,
        email,
        addons: {
          extra_users,
          extra_companies,
        },
        companyName,
      });
    },
    retry: 3,
  });

  useEffect(() => {
    firebaseAuth.getRecaptchaVerify();
  }, []);

  // Only visible on Desktop - so make it helpful but not critical:
  let leftContent = sentCode ? (
    <p className="text-xl">
      Almost there - check your phone for the code we sent you.
    </p>
  ) : (
    <p className="text-xl">
      Welcome to SnowScape! We're excited to have you join us. To get started,
      fill in some basic information about your company.
    </p>
  );

  const requestCodeFromFirebase = useCallback(async (data) => {
    try {
      const phoneNumber = data.phone;
      const cleanedNumber = "+1" + ("" + phoneNumber).replace(/\D/g, "");
      await firebaseAuth.sendSMSAsync(cleanedNumber);
      setSentCode(true);
    } catch (err) {
      errors.report(err);
      setFormError(
        "Unable to send code - please check your phone number and try again.",
      );
    }
  }, []);

  const resendCode = useCallback(async () => {
    try {
      const cleanedNumber = "+1" + ("" + formData.phone).replace(/\D/g, "");
      await firebaseAuth.sendSMSAsync(cleanedNumber);
    } catch (err) {
      errors.report(err);
      setFormError(
        "Unable to send code - please check your phone number and try again.",
      );
    }
  }, [formData]);

  const openStripeCheckoutPage = useCallback(
    async (plan, extra_users, extra_companies, email) => {
      setFormError(null);
      try {
        await goToStripe(plan, extra_users, extra_companies, email);
      } catch (error) {
        setFormError(error);
      }
    },
    [],
  );

  const setupUserAndCompany = useCallback(
    async (fbUser, formData) => {
      try {
        // Create a new user and send it to the backend:
        const newUser = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: fbUser.phoneNumber,
          startDate: new Date().toLocaleDateString(),
          uid: fbUser.uid,
          agreeToTerms: formData.agreeToTerms,
        };
        try {
          const userData = await axiosUsers.createNewUserAsync(newUser);
          if (!userData.successfullyAdded) {
            throw new Error("Failed to save your data - please try again");
          }
          dispatch(updateUser(userData.user));
        } catch (err) {
          // It's a normal flow that the user might already exist, but went through sign-up anyway:
          if (err.response?.data?.code == "user-already-exists") {
            let userData = err.response?.data?.user;
            dispatch(updateUser(userData));
            if (userData.stripe?.customerId) {
              alert(
                "You already have an account. We will log you in now.  If you want to change your plan, go to Settings > Billing.",
              );
              navigate("/admin/settings/billing");
              return;
            }
            // If they don't have a stripe customer id, we need to let them keep going through the checkout process
          } else {
            throw err; // shouldn't get here
          }
        }

        // If we were passed an existing company - make it the current company:
        if (formData.existingCompanyId) {
          dispatch(
            updateUser({ currentCompanyId: formData.existingCompanyId }),
          );
        }

        // And create a company if the form data has a company name
        let companyDataPromise = null;
        if (formData.companyName) {
          companyDataPromise = axios
            .post("company/add", {
              name: formData.companyName,
              uid: fbUser.uid,
            })
            .then((resp) => {
              dispatch(
                // This line makes sure they have a company set when they login and updates our cached user's companies
                updateUser({
                  ...resp.data.user,
                  currentCompanyId: resp.data.company._id,
                }),
              );
            });
        }

        // Now lookup the plan and handle the subscription:
        let planData = PricingData.find((i) => i.id == formData.plan.id);
        if (!planData) {
          // Normally should not be able to get here (unless we have a bug or the user is tweaking the url)
          alert(
            "Sorry - we couldn't find the plan you selected - please try again",
          );
          navigate("/sign-up");
          return;
        }
        if (
          planData.price <= 0 &&
          formData.extra_users < 1 &&
          formData.extra_companies < 1
        ) {
          // Create stipe customer without collecting payment data
          await signUpForPlanWithSubscription.mutateAsync({
            plan: formData.plan.id,
            email: formData.email,
            companyName: formData.companyName,
          });
          await companyDataPromise; // make sure this has finished before we move on
          navigate("/admin/dashboard?show_purchase_success=1");
          return;
        } else {
          await companyDataPromise; // make sure this has finished before we move on
          await openStripeCheckoutPage(
            formData.plan.id,
            formData.extra_users,
            formData.extra_companies,
            formData.email,
          );
          return;
        }
      } catch (err) {
        errors.report(err);
        setFormError(
          err.response?.data?.message ??
            err.message ??
            "Please contact support",
        );
      }
    },
    [dispatch, navigate, signUpForPlanWithSubscription, openStripeCheckoutPage],
  );

  const onBasicInfoSubmit = useCallback(
    async (data) => {
      setFormData(data);
      if (firebaseUser?.uid) {
        await setupUserAndCompany(firebaseUser, data); // we are already logged in
      } else {
        await requestCodeFromFirebase(data); // not logged in yet - need to collect code
      }
    },
    [firebaseUser, requestCodeFromFirebase, setupUserAndCompany],
  );

  const validateCodeAndCheckout = useCallback(
    async (verificationCode) => {
      let fbUser = null;
      try {
        // Send code to Firebase
        fbUser = await firebaseAuth.signInWithCodeAsync(verificationCode);
      } catch (error) {
        if (error.code === "auth/invalid-verification-code") {
          setFormError("Invalid Code - Please try again.");
        } else if (error.code === "auth/code-expired") {
          setFormError(
            'Your code has expired.  Please click "Resend Code" above and try again.',
          );
        } else {
          setFormError(error);
        }
      }
      if (fbUser) {
        // if the previous step had an error, we might not have a user
        await setupUserAndCompany(fbUser, formData);
      }
    },
    [formData, setupUserAndCompany],
  );

  return (
    <>
      <SplitForm leftContent={leftContent}>
        <div className="grid h-full">
          <div className="text-full-white place-self-center">
            {sentCode ? (
              <ValidateCodeForm
                phone={formData.phone}
                resendCode={resendCode}
                handleSubmit={validateCodeAndCheckout}
              />
            ) : (
              <BasicInfoForm
                handleSubmit={onBasicInfoSubmit}
                selectedPlan={selectedPlan}
                currentUser={currentUser}
              />
            )}
            {formError ? (
              <div
                className="text-snow-red p-2"
                data-testid="sign-up-page-error-message"
              >
                {formError.message ?? formError}
              </div>
            ) : null}
          </div>
        </div>
      </SplitForm>
    </>
  );
};

async function goToStripe(plan, extra_users, extra_companies, email) {
  let products = [
    {
      lookup_key: plan,
      quantity: 1,
      type: "subscription",
    },
  ];
  if (extra_users > 0) {
    products.push({
      lookup_key: AddOnPricing.extra_users.id,
      quantity: extra_users,
      type: "subscription",
    });
  }
  if (extra_companies > 0) {
    products.push({
      lookup_key: AddOnPricing.extra_companies.id,
      quantity: extra_companies,
      type: "subscription",
    });
  }
  const sessionData = await axios.post("/stripe/create-checkout-session", {
    products: products,
    email: email,
  });
  window.location.href = sessionData.data.sessionUrl;
}

export { goToStripe, SignUpPage };
export default connect((state) => ({ currentUser: state.currentUser }))(
  SignUpPage,
);
