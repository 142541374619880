import React from "react";

import EmailIcon from "../../resources/landing-page/email.svg";
import PhoneIcon from "../../resources/landing-page/phone.svg";
import SnowScapeLogoWithWhiteText from "../../resources/logo/snowscape-logo-horizontal-with-white-text.svg";
import { Link } from "react-router-dom";
import {
  phoneNumber,
  phoneNumberFormatted,
} from "../../pages/LandingPage/ContactUsPage";

const Footer = ({ className = "", ...props }) => {
  return (
    <div
      className={`${className} bg-landing-page-black py-8 text-full-white z-10 relative`}
      {...props}
    >
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row">
        <div className="lg:w-1/3 px-4 mx-auto">
          <img
            alt="snowscape-logo"
            className="w-[350px] lg:w-[270px]"
            src={SnowScapeLogoWithWhiteText}
          />
          <div className="mt-8 mb-2 lg:mt-4">
            <div className="flex items-center gap-4">
              <img src={EmailIcon} alt="email-icon" className="w-6 h-6" />
              <a href="mailto:info@mysnowscape.com">info@mysnowscape.com</a>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <img src={PhoneIcon} alt="phone-icon" className="w-6 h-6" />
              <a href={`tel:${phoneNumber}`}>{phoneNumberFormatted}</a>
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 flex mt-4 lg:mt-0 text-center">
          <div className="w-1/2">
            <h3 className="text-xl text-snow-white uppercase">Links</h3>
            <ul className="text-snow-white mt-4">
              <li>
                <Link to="/" className="hover:cursor-pointer underline">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:cursor-pointer underline">
                  About
                </Link>
              </li>
              <li>
                <Link to="/features" className="hover:cursor-pointer underline">
                  Features
                </Link>
              </li>
              <li>
                <Link to="/docs" className="hover:cursor-pointer underline">
                  Tutorials
                </Link>
              </li>
              <li>
                <Link to="/terms" className="hover:cursor-pointer underline">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2">
            <h3 className="text-xl text-snow-white text-center uppercase">
              Social
            </h3>
            <ul className="text-snow-white text-center mt-4">
              <li>
                <a
                  className="hover:cursor-pointer underline"
                  href="https://www.facebook.com/p/SnowScape-61552998152393/"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-4 lg:mt-0 lg:w-1/3">
          <h3 className="text-xl text-snow-white text-center uppercase">
            Join Us Today!
          </h3>
          <Link
            to="/contact"
            className="hover:cursor-pointer block mt-4 text-center font-snow-white underline"
          >
            Contact Us
          </Link>
          {/* <div className="flex items-center gap-2 px-4">
            <input
              type="email"
              placeholder="Email"
              className="w-full mt-4 flex-1 h-12 px-4"
            />
            <Button className="mt-4">Get Started</Button>
          </div> */}
        </div>
      </div>
      <div className="flex items-center justify-center mt-10 text-xs">
        Copyright {new Date().getFullYear()} Snowscape.com all rights reserved
      </div>
    </div>
  );
};

export default Footer;
