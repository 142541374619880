import React from "react";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";

const AboutPage = () => {
  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px] terms">
          <div className="text-snow-white font-bold text-5xl text-center font-catamaran">
            Terms & Conditions
          </div>
          <div className="mt-20 text-snow-white font-light text-2xl text-left font-noto">
            <p>
              By clicking to accept or agree to the Terms when this option is
              made available to you, you accept and agree to be bound and abide
              by the Terms and our Privacy Policy (collectively, “Agreement”).
              This Agreement forms a binding legal Agreement between you (and
              any other entity on whose behalf you accept these terms)
              (collectively “You” or “Your”) and Mysnowscape.com (“Snow Scape”)
              (each separately a “Party” and collectively the “Parties”) as of
              the date you download the Mobile App. Your use of the Mobile App
              is subject to this Agreement. You may have entered into a separate
              client Agreement with a vendor or subscriber. With respect to the
              use of the Mobile App, and to the extent any vendor or subscriber
              Agreement may conflict with this Agreement, the terms of this
              Agreement will govern and control solely with respect to use of
              the Mobile App, and Snow Scape is not a party to any vendor or
              subscriber Agreement.
            </p>
            <ol>
              <li>
                <b>License.</b> Snow Scape grants You a revocable,
                non-exclusive, non-transferable, limited license to download,
                install, and use the Mobile App for Your personal and internal
                business purposes strictly in accordance with this Agreement.
              </li>
              <li>
                <b>Changes to this Agreement.</b> Snow Scape reserves the right
                to modify this Agreement at any time and for any reason. Snow
                Scape will post the most current version of this Agreement at
                its website or through its mobile app and You will receive
                notification of the changes via the Mobile App. Your continued
                use of the Mobile App after Snow Scape publishes notice of
                changes to this Agreement indicates Your consent to the updated
                terms.
              </li>
              <li>
                <b>Maintenance and Support.</b> Snow Scape may deploy changes,
                updates, or enhancements to the Mobile App at any time.{" "}
              </li>
              <li>
                <b>Acceptable Use.</b> You agree that You will not use or
                encourage others to use the Mobile App or the client-facing
                Services as accessed through the Mobile App in an unlawful
                manner or in any way that could harm or impair others' use of
                the Mobile App or the Services.{" "}
              </li>
              <li>
                <b>Consent to Electronic Communications.</b> By downloading the
                Mobile App, You authorize Snow Scape to send You (including via
                email and push notifications) information regarding the Mobile
                App, such as: (a) notices about Your use of the Mobile App,
                including notices of violations of use; and (b) updates to the
                Mobile App and new features or products. You can review Your
                account notification settings and adjust Your messaging
                preferences, including opting-in to additional messages or
                unsubscribing to certain messaging through the “Push
                Notifications” section of the Mobile App settings.
              </li>
              <li>
                <b>No Warranty.</b> YOUR USE OF THE MOBILE APP IS AT YOUR SOLE
                RISK. THE MOBILE APP IS PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. SNOW SCAPE EXPRESSLY DISCLAIMS ALL WARRANTIES
                OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                AND NON-INFRINGEMENT.
                <p>
                  The Mobile App is only available for supported devices and
                  might not work on every device. Determining whether Your
                  device is a supported or compatible device for use of the
                  Mobile App is solely Your responsibility, and downloading the
                  Mobile App is done at Your own risk. Snow Scape does not
                  represent or warrant that the Mobile App and Your device are
                  compatible or that the Mobile App will work on Your device.
                </p>
              </li>
              <li>
                <b>Suspension and Termination of the Mobile App.</b> Snow Scape
                reserves the right to suspend or terminate Your access to the
                Mobile App at any time based on the status of Subscriber's
                subscription to the Services. You understand that if
                Subscriber's account is suspended or terminated, You may no
                longer have access to access content through the Mobile App.
              </li>
              <li>
                <b>Legal Compliance.</b> You represent and warrant that: (a) You
                are not located in a country that is subject to a United States
                Government embargo, or that is on Title 15, Part 740 Supplement
                1 Country Group E of the United States Code of Federal
                Regulations; and (b) You are not listed on any U.S. Government
                list of prohibited or restricted parties.
              </li>
              <li>
                <b>Governing Law.</b> This Agreement shall be governed
                exclusively by, and will be enforced, construed, and interpreted
                exclusively in accordance with, the laws applicable to the state
                of Michigan and shall be considered to have been made and
                accepted in the state of Michigan. All disputes under this
                Agreement will be resolved by the courts of Oakland County,
                state of Michigan and You consent to the jurisdiction of and
                venue in such courts and waive any objection as to inconvenient
                forum. In any action or proceeding to enforce any rights under
                this Agreement, Snow Scape shall be entitled to recover costs
                and legal fees.
              </li>
              <li>
                <b>Limit on Types of Damages Recoverable.</b> NEITHER PARTY NOR
                ITS AFFILIATES, SUBSIDIARIES, EMPLOYEES, CONTRACTORS, OR
                SUPPLIERS, SHALL BE LIABLE TO THE OTHER PARTY OR ANY OTHER THIRD
                PARTY FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, OR
                PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS,
                LOST REVENUES, AND LOSS OF BUSINESS OPPORTUNITY) THAT THE OTHER
                PARTY MAY INCUR OR EXPERIENCE IN CONNECTION WITH THIS AGREEMENT
                OR THE SERVICES, HOWEVER CAUSED AND UNDER WHATEVER THEORY OF
                LIABILITY (INCLUDING, WITHOUT LIMITATION, STRICT LIABILITY AND
                NEGLIGENCE), EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL SNOWSCAPE BE
                LIABLE FOR ANY DAMAGES RELATED TO ANY THIRD-PARTY PRODUCT.
              </li>
              <li>
                <b>Limitation of Actions.</b> Neither Party may bring any
                action, regardless of form, arising out of or relating to an
                Order or this Agreement more than six (6) months from the date
                on which the cause of action arose.
              </li>
              <li>
                <b>Limit on the Amount of Damages Recoverable.</b> Snow Scape's
                liability to You and Your respective officers, directors,
                employees, agents, contractors, successor and assigns arising
                under or related to this Agreement provided hereunder shall be
                limited in all cases to direct damages which shall not exceed
                the amount of fees paid by You during the six (6) month period
                immediately preceding the event giving rise to the claim for
                damages.
              </li>
              <li>
                <b>No Liability for Certain Actions.</b> Snow Scape shall not be
                liable to You for any claims or damages resulting from or caused
                by (a) unauthorized access to transmission facilities or
                premises equipment, or for unauthorized access to or alteration,
                theft, or destruction of data files, programs, procedure, or
                information through accident, wrongful means or devices, or any
                other method where such unauthorized access is due to Your
                fault, negligence or failure to perform Your responsibilities;
                (b) Your fault, negligence or failure to perform Your
                responsibilities; (c) claims against You by any other party; (d)
                any act or omission of any other party; or (e) equipment or
                services furnished by a third party. Snow Scape is not
                responsible for customer data or the content of any other
                information transmitted or received through the Mobile App or
                other wise.
              </li>
              <li>
                <b>Indemnification by Customer.</b> You shall indemnify, defend
                and hold harmless Snow Scape and its officers, directors, agents
                and employees, from and against any and all losses, claims,
                demands, causes of action, litigation, lawsuits and any other
                claim arising out of or in any manner relating to: (a) violation
                of any law by You; (b) any claim for withholding or other taxes
                that might arise or be imposed due to this Agreement or the
                performance hereof; (c) damage to property or personal injury
                (including death) arising out of the gross negligence or willful
                acts or omissions of You; or (d) claims by a third-party arising
                out of or related to the use or misuse of Snow Scape's mobile
                app, software or website.
              </li>
              <li>
                <b>Privacy.</b> n order to operate and provide the Mobile App,
                Snow Scape may collect certain personal information about You
                that you voluntarily submit in order to use the Services,
                including technical, location data and telemetry data related to
                your use of the Mobile App, which is subject to the following:
                <ol type="A">
                  <li>
                    Snow Scape operates a cloud-based legal practice management
                    solution available via our websites (our “<b>Websites</b>”)
                    including at mysnowscape.com and mobile application as well
                    as other products and services that we make available (the “
                    <b>Service</b>”).
                  </li>
                  <li>This privacy policy applies to the Service.</li>
                  <li>
                    Before accessing or using the Service, please ensure that
                    you have read and understood Snow Scape's collection,
                    storage, use and disclosure of your personal information as
                    described in this privacy policy. By accessing or using the
                    Service, you are accepting and consenting to the practices
                    described in this privacy policy.
                  </li>
                  <li>
                    <b>Information You give to Snow Scape.</b> We collect
                    personal information (including but not limited to location
                    and other personal information) about you when You
                    voluntarily submit information directly to Snow Scape by
                    using the Mobile App or using Snow Scape's Website.{" "}
                  </li>
                  <li>
                    <b>Information we receive from other sources.</b> We may
                    receive personal information about you from individuals or
                    corporate entities which are subscribers to the Snow Scape's
                    Services (“<b>Subscribers</b>“).{" "}
                  </li>
                  <li>
                    Snowscape agrees it will: (a) only use confidential
                    information for the Purpose stated above; (b) not disclose
                    confidential information to any third-party for any reason
                    without your prior written consent, and (c) not use such
                    confidential information to compete against you.
                  </li>
                  <li>
                    Confidential information does not include information that:
                    (a) is or has been within the public domain or enters the
                    public domain without restriction through no action of
                    Snowscape; (b) is rightfully received by us from a
                    third-party without obligation of confidentiality to You;
                    (c) was already known to Snowscape before disclosure; (d)
                    was or is independently developed by us, without reference
                    to or use of, in whole or in part, any of the confidential
                    information; (e) we are required to disclose by law under
                    the order of any legal or governmental agency, or similar
                    authorities.
                  </li>
                  <li>
                    In certain situations, we may be required to disclose
                    personal information in response to lawful requests by
                    public authorities, including to meet law enforcement
                    requirements. We may disclose personal information in order
                    to comply with any legal obligation, and other agreements;
                    or to protect the rights, property, or safety of Snow Scape,
                    our customers, or others. This includes exchanging
                    information with other companies and organizations for the
                    purposes of fraud protection and credit risk reduction.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </WithSnowFlakes>
  );
};

export default AboutPage;
