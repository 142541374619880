import React from "react";

// Components
import SubtleButton from "../../../../components/Buttons/SubtleButton";
import JobCard from "../../../../components/Cards/JobCard";
import StandardLabel from "../../../../components/Labels/StandardLabel";
import TwistedArrow from "../../../../resources/twisted-arrow.svg";
import DragDropCardWrapper from "../../../../components/Cards/DragDropCardWrapper";
import { DragDropProvider } from "@dnd-kit/react";
import { move } from "@dnd-kit/helpers";

const EditProperties = (props) => {
  return (
    <div className="w-full mt-4">
      <div className="flex flex-col w-full items-end mb-2.5">
        <SubtleButton
          onClick={() => props.setShowAddPropertyWindow(true)}
          testId="add-properties-button"
          disabled={props.showAddPropertyWindow}
        >
          + Add Properties
        </SubtleButton>
      </div>
      {props.isNew ? null : (
        <div
          className="flex flex-row justify-end align-top w-full mb-2.5 pr-3"
          data-testid="order-properties-text"
        >
          <span className="mr-2 font-hand font-bold text-lg">
            Drag / drop your properties here
          </span>
          <img alt="->" className="w-10 relative top-4" src={TwistedArrow} />
        </div>
      )}
      {props.jobs.length > 0 ? <StandardLabel label="Properties" /> : null}
      <div data-testid="existing-properties">
        <DragDropProvider
          onDragEnd={(event) => {
            const movedJobId = event.operation.target?.id;
            if (movedJobId) {
              const rearrangedJobs = move(props.jobs, event);
              props.updateOrder(rearrangedJobs);
            }
          }}
        >
          {props.jobs.map((job, index) => (
            <DragDropCardWrapper jobId={job.id} key={job.id} index={index}>
              <JobCard
                address={job.address}
                name={job.name}
                onCardPress={null}
                onRightIconClicked={() => {
                  const updatedJobs = [...props.jobs];
                  updatedJobs.splice(index, 1);
                  props.setJobs([...updatedJobs]);
                }}
                showDelete={true}
              />
            </DragDropCardWrapper>
          ))}
        </DragDropProvider>
      </div>
    </div>
  );
};

export default EditProperties;
