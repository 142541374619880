import React from "react";
import SidepanelHeader from "../../../../../../components/Maps/ActiveRoutes/ui/sidepanel-header";
import { colors } from "../../../../../../components/Maps/ActiveRoutes/colors";

export const HeaderWithDetails = ({
  isLoading,
  routeChanged,
  routeName,
  routeColor,
  routeDetails,
}) => {
  const name = routeName ?? <em>Unnamed route</em>;
  const details =
    routeDetails !== "" ? routeDetails : <em>No route details yet</em>;

  return (
    <div className="px-5 py-4 border-b border-gray-200">
      <div className="flex items-start justify-between">
        <SidepanelHeader>
          <div className="grid grid-rows">
            <div className="text-lg grid grid-cols-[35px_auto]">
              <ColorIcon color={routeColor} />
              <div>
                {name}
                <div className="text-xs font-light">
                  {isLoading ? (
                    <em>Calculating</em>
                  ) : routeChanged ? (
                    <em>Save route for details</em>
                  ) : (
                    details
                  )}
                </div>
              </div>
            </div>
          </div>
        </SidepanelHeader>
      </div>
    </div>
  );
};

export const ColorIcon = ({ color, enabled }) => {
  const disabled = enabled === false ? "opacity-50" : "";

  return (
    <span
      className={`inline-flex mt-1 mr-2 -mb-1 size-5 items-center justify-center rounded-full ${colors[color].bg} ${disabled}`}
    />
  );
};
