import React from "react";

import { useSortable } from "@dnd-kit/react/sortable";

import { RestrictToVerticalAxis } from "@dnd-kit/abstract/modifiers";
import { DragDropProvider } from "@dnd-kit/react";
import { move } from "@dnd-kit/helpers";
import { colors } from "../../../../components/Maps/ActiveRoutes/colors";

import TrashRed from "../../../../resources/trash-can-red.svg";

import { formatAddress } from "./Routes/utils";
import SubtleButton from "../../../../components/Buttons/SubtleButton";
import { StartEndLabelAddress } from "./Routes/Components/StartEndAddress";
import { DragDropIcon } from "./UI/DragDropIcon";

export const EditableJobsForRoute = ({
  locationChooser,
  jobs,
  highlightJobId,
  color,
  routeLocations,
  showLocations,
  onAdd,
  onClick,
  onDelete,
  updateOrder,
}) => {
  const haveJobs = jobs.length > 0;
  return (
    <nav aria-label="Progress" className="relative mt-1">
      <StartEndLabelAddress
        type="start"
        isSelected={routeLocations.start?.location}
      >
        {locationChooser("start")}
      </StartEndLabelAddress>

      <DragDropProvider
        onDragEnd={(event) => {
          const movedJobId = event.operation.target.id;
          updateOrder(move(jobs, event), movedJobId);
        }}
      >
        {haveJobs ? (
          <ol role="list" className="overflow-hidden">
            {jobs.map((job, index) => (
              <EditableJobItem
                key={job.id}
                index={index}
                job={job}
                highlight={highlightJobId === job.id}
                showLocations={showLocations}
                color={color}
                onDelete={() => (onDelete ? onDelete(job.id) : null)}
                onClick={onClick}
              />
            ))}
          </ol>
        ) : (
          <div className="mt-6 mb-12 pt-6 text-center">
            <SubtleButton
              buttonRole="primary"
              onClick={(e) => {
                onAdd(e);
              }}
            >
              Add a Job to Your Route
            </SubtleButton>
          </div>
        )}
      </DragDropProvider>
      <StartEndLabelAddress
        type="end"
        isSelected={routeLocations.end?.location}
      >
        {locationChooser("end")}
      </StartEndLabelAddress>
    </nav>
  );
};

const EditableJobItem = ({
  index,
  job,
  color,
  highlight,
  onDelete,
  onClick,
  showLocations,
}) => {
  return (
    <ShowAddressRow index={index} jobId={job.id} showLocations={showLocations}>
      <JobStep
        name={job.name}
        address={job.address}
        number={index}
        color={color}
        highlight={highlight}
        isNew={job.isNew ?? false}
        showLocations={showLocations}
        onClick={() => {
          onClick(job.id);
        }}
      />
      <button onClick={onDelete} className="invisible group-hover:visible">
        <img className="w-4" src={TrashRed} alt="trash" />
      </button>
    </ShowAddressRow>
  );
};

const ShowAddressRow = ({ index, jobId, showLocations, children }) => {
  const height = showLocations ? "min-h-16 mb-2" : "min-h-8 mb-1";
  const { ref } = useSortable({
    id: jobId,
    index,
    modifiers: [RestrictToVerticalAxis],
  });

  return (
    <li
      ref={ref}
      className={`relative group ${height} grid grid-cols-[12px_auto_20px] items-start`}
    >
      <DragDropIcon />
      {children}
    </li>
  );
};

export const JobStep = ({
  name,
  address,
  highlight,
  isNew,
  showLocations,
  number,
  color,
  onClick,
  wide,
}) => {
  const bgColor = highlight
    ? colors[color].bgHover
    : isNew
      ? `${colors[color].bg} opacity-50`
      : colors[color].bg;
  const bgHoverColor = colors[color].bgHover;

  const fullAddress = showLocations ? formatAddress(address) : "";
  const textSpacing = showLocations ? "mt-0" : "mt-0.5";
  const btnWidth =
    wide || wide !== false ? "grid-cols-[40px_auto]" : "grid-cols-[25px_auto]";
  const leftMargin = wide || wide !== false ? "ml-4" : "ml-0";

  return (
    <button onClick={onClick} className={`relative grid ${btnWidth}`}>
      <span
        className={`${leftMargin} flex min-w-0 flex-col size-6 rounded-full ${bgColor} hover:${bgHoverColor} group-hover:${bgHoverColor} text-white text-sm pt-0.5`}
      >
        {number + 1}
      </span>
      <span
        className={`ml-3 ${textSpacing} min-w-0 flex-col flex text-sm font-medium text-gray-500 text-left`}
      >
        <span className="">{name}</span>
        {showLocations ? <span>{fullAddress}</span> : null}
      </span>
    </button>
  );
};

export const AddJobIcon = ({ isSelected, previouslySelected }) => {
  const circleColor = isSelected
    ? "border-blue-600 group-hover:border-blue-800"
    : previouslySelected
      ? "border-blue-300 group-hover:border-blue-400"
      : "border-gray-300 group-hover:border-gray-400";

  const color = isSelected
    ? "bg-blue-600 group-hover:bg-blue-800"
    : previouslySelected
      ? "bg-blue-300 group-hover:bg-blue-400"
      : "bg-gray-200 group-hover:bg-gray-300";

  return (
    <button className="group relative flex items-start">
      <span className="flex h-8 items-center" aria-hidden="true">
        <span
          className={`relative z-10 flex size-5 items-center justify-center rounded-full border-2 bg-white ${circleColor}`}
        >
          <span className={`size-2 rounded-full ${color}`}></span>
        </span>
      </span>
    </button>
  );
};
