import React from "react";

import { colors } from "../colors";

export const RouteDetailsProgressBar = ({
  jobs,
  color,
  noProgress,
  onClick,
}) => {
  let i = 0;

  return (
    <nav aria-label="Progress" className="mt-4">
      <ol role="list" className="overflow-hidden">
        {jobs.map((job, key) => {
          let isLast = jobs.length - 1 == i;
          i++;
          return (
            <li key={key} className="relative">
              <Step
                status={noProgress ? "no-progress" : job.status}
                name={job.name}
                color={color}
                isLast={isLast}
                onClick={() => onClick(job._id)}
              />
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

const Step = ({ status, name, color, isLast, onClick }) => {
  if (status === "complete") {
    return <CompleteStep name={name} color={color} onClick={onClick} />;
  }
  if (status === "current") {
    return <CurrentStep name={name} color={color} onClick={onClick} />;
  }
  if (status === "upcoming" || status === "no-progress") {
    return <UpcomingStep name={name} isLast={isLast} onClick={onClick} />;
  }
};

const CompleteStep = ({ color, name, onClick }) => {
  let bgColor = colors[color].bg;
  let textHoverColor = colors[color].textHover;

  return (
    <>
      <div
        className={`absolute left-2.5 top-4 -ml-px mt-0.5 h-full w-0.5 ${bgColor}`}
        aria-hidden="true"
      ></div>
      {/* Complete Step */}
      <button
        onClick={onClick}
        className={`group relative flex items-center hover:${textHoverColor}`}
      >
        <span className="flex h-8 items-center">
          <CompleteIcon color={color} />
        </span>
        <span className="ml-4 flex min-w-0 flex-col">
          <span className="text-sm font-medium">{name}</span>
          {/* <span className="text-sm text-gray-500">
            Vitae sed mi luctus laoreet.
          </span> */}
        </span>
      </button>
    </>
  );
};

const CurrentStep = ({ color, name, onClick }) => {
  let textColor = colors[color].text;
  let textHoverColor = colors[color].textHover;

  return (
    <>
      <div
        className="absolute left-2.5 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
        aria-hidden="true"
      ></div>
      {/* Current Step */}
      <button
        onClick={onClick}
        className={`group relative flex items-center text-sm font-medium ${textColor} group-hover:${textHoverColor}`}
        aria-current="step"
      >
        <span className="flex h-8 items-center" aria-hidden="true">
          <CurrentIcon color={color} />
        </span>
        <span className="ml-4 flex min-w-0 flex-col">
          <span
            className={`text-sm font-medium ${textColor} hover:${textHoverColor}`}
          >
            {name}
          </span>
          {/* <span className="text-sm text-gray-500">
            Cursus semper viverra facilisis et et some more.
          </span> */}
        </span>
      </button>
    </>
  );
};

const UpcomingStep = ({ name, onClick, isLast }) => {
  const last = isLast ?? false;
  return (
    <>
      {!last ? (
        <div
          className="absolute left-2.5 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
          aria-hidden="true"
        ></div>
      ) : null}
      {/* Upcoming Step */}
      <button onClick={onClick} className="group relative flex items-center">
        <span className="flex h-8 items-center" aria-hidden="true">
          <UpcomingIcon />
        </span>
        <span className="ml-4 flex min-w-0 flex-col">
          <span className="text-sm font-medium text-gray-500">{name}</span>
        </span>
      </button>
    </>
  );
};

export const CompleteIcon = ({ color }) => {
  let bgColor = colors[color].bg;
  let bgHover = colors[color].bgHover;

  return (
    <span
      className={`relative z-10 flex size-5 items-center justify-center rounded-full ${bgColor} group-hover:${bgHover}`}
    >
      <CompleteCheck />
    </span>
  );
};

export const CompleteCheck = () => (
  <svg
    className="size-3 text-white"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    data-slot="icon"
  >
    <path
      fillRule="evenodd"
      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
      clipRule="evenodd"
    />
  </svg>
);

const CurrentIcon = ({ color }) => {
  let borderColor = colors[color].border;
  let bgColor = colors[color].bg;

  return (
    <span
      className={`relative z-10 flex size-5 items-center justify-center rounded-full bg-white border-2 ${borderColor}`}
    >
      <span className={`size-2 rounded-full ${bgColor}`}></span>
    </span>
  );
};

const UpcomingIcon = () => {
  return (
    <span className="relative z-10 flex size-5 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
      <span className="size-2 rounded-full bg-gray-200 group-hover:bg-gray-300"></span>
      {/* <span className="size-2 rounded-full bg-transparent group-hover:bg-gray-300"></span> */}
    </span>
  );
};
