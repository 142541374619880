import React, { useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// Services
import { RouteContext } from "../RouteContext";
import PreviewPane from "./Preview/Pane";
import { useEffect } from "react";
import { useState } from "react";
import { useMapPathZoom } from "../../../../../components/Maps/ActiveRoutes/use-map-zoom";
import NotificationModal from "../../../../../components/Modals/NotificationModal";

const PreviewRoute = () => {
  let { routeId } = useParams();
  const navigate = useNavigate();

  // pull jobs for this route of route context
  const { jobs, route, routeColor, routeLocations, setPreviewedRoute } =
    useContext(RouteContext);

  const [loading, setLoading] = useState(false);
  const [caughtError, setCaughtError] = useState(false);
  const [drive, setDrive] = useState({
    time: 0,
    distance: "",
    drivingDistanceMeters: 0,
    encodedPath: "",
  });
  const [routePaths, setRoutePaths] = useState([]);

  // TODO: adjust padding allowed on the zoom
  useMapPathZoom(routePaths);

  useEffect(() => {
    setLoading(true);
    axios
      .post(`route/calculate-path`, {
        start: routeLocations.start.location,
        end: routeLocations.end.location,
        jobIds: jobs.map((j) => j._id),
      })
      .then((resp) => {
        let payload = {
          time: resp.data.route.drivingDurationSeconds,
          distance: resp.data.route.drivingDistanceFormatted,
          drivingDistanceMeters: resp.data.route.drivingDistanceMeters,
          encodedPath: resp.data.route.drivingPath,
        };
        setDrive(payload);
        setRoutePaths([payload.encodedPath]);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setCaughtError(error.response.data);
        } else {
          throw error;
        }
      })
      .finally(() => setLoading(false));
  }, [jobs, routeId, routeLocations]);

  const closeRoute = () => {
    setPreviewedRoute({
      drivingDistanceFormatted: drive.distance,
      drivingDistanceMeters: drive.drivingDistanceMeters,
      drivingDurationSeconds: drive.time,
      drivingPath: drive.encodedPath,
    });
    navigate("/admin/route/" + routeId, {
      preventScrollReset: true,
    });
  };
  const closeErroredRoute = () => {
    navigate("/admin/route/" + routeId, {
      preventScrollReset: true,
    });
  };

  return (
    <>
      {caughtError ? (
        <NotificationModal
          button={{
            color: "blue",
            label: "Okay",
            onClick: () => {
              closeErroredRoute();
            },
          }}
          open={true}
          subtitle={caughtError.message}
          title="Errors"
        />
      ) : (
        <PreviewPane
          isLoading={loading}
          drive={drive}
          route={route}
          routeLocations={routeLocations}
          routeColor={routeColor}
          jobs={jobs}
          onClose={closeRoute}
        />
      )}
    </>
  );
};

export default PreviewRoute;
