import React from "react";
import { useState, useEffect } from "react";
import SubtleButton from "../../../../../../components/Buttons/SubtleButton";
import SidepanelHeader from "../../../../../../components/Maps/ActiveRoutes/ui/sidepanel-header";
import SidepanelFooter from "../../SidepanelFooter";
import RoutePath from "../../../../../../components/Maps/ActiveRoutes/RoutePath";
import { colors } from "../../../../../../components/Maps/ActiveRoutes/colors";
import { MapMarker, StartEndMapMarker } from "../../Map/Marker";
import LoadingIcon from "../../../../../../components/Loaders/LoadingIcon";
import { useMapPathZoom } from "../../../../../../components/Maps/ActiveRoutes/use-map-zoom";

import { intervalToDuration } from "date-fns";
import { ColorIcon } from "../Components/HeaderWithDetails";

const RouteCompare = ({
  color,
  name,
  distance,
  time,
  selected,
  updateShowOptimized,
}) => {
  const highlightedCss = selected
    ? "border-slate-400 bg-gray-50"
    : "border-slate-200 text-gray-700 border-b-2 border-b-slate-400";

  const duration = intervalToDuration({ start: 0, end: time * 1000 });
  let formattedTime = "";
  if (duration.years > 0 || duration.months > 0 || duration.days > 0) {
    formattedTime = "Over a day";
  }
  if (duration.hours > 0) {
    formattedTime =
      formattedTime +
      duration.hours +
      " hr" +
      (duration.hours > 1 ? "s " : " ");
  }
  if (duration.minutes > 0) {
    formattedTime =
      formattedTime +
      " " +
      duration.minutes +
      " min" +
      (duration.minutes > 1 ? "s" : "");
  }

  return (
    <button
      onClick={updateShowOptimized}
      className={`grid grid-cols-[30px_auto] gap-0 text-left border-solid border-2 border-b-0 rounded-t hover:bg-gray-50 p-2 ${highlightedCss}`}
    >
      <ColorIcon color={color} enabled={selected} />
      <div>
        {name}
        <div className="text-xs font-light">
          {distance}
          <br /> {formattedTime}
        </div>
      </div>
    </button>
  );
};

const OptimizePane = ({
  isLoading,
  route,
  routeLocations,
  comparison,
  onCancel,
  onOptimize,
}) => {
  const [showOptimized, setShowOptimized] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [displayPath, setDisplayPath] = useState([]);

  const optimizeRoute = () => {
    onOptimize(comparison.route);
  };

  const onChangeRoute = (viewNewRoute) => {
    const showRoute = viewNewRoute ? comparison.route : comparison.oldRoute;
    setShowOptimized(viewNewRoute);
    setSelectedRoute(showRoute);
    setDisplayPath([showRoute.drivingPath]);
  };

  useEffect(() => {
    if (isLoading) return;

    setSelectedRoute(comparison.route);
    setDisplayPath([comparison.route.drivingPath]);
  }, [isLoading, comparison.route]);

  // TODO: adjust padding allowed on the zoom
  useMapPathZoom(displayPath);

  const newRouteColor = "emerald";
  const originalRouteColor = "blue";

  const newRouteColorCompleted = colors[newRouteColor].hex;
  const originalRouteColorCompleted = colors[originalRouteColor].hex;

  return (
    <div className="h-full grid grid-rows-[55px_auto_77px]">
      <div className="px-5 py-4 border-b border-gray-200">
        <div className="flex items-start justify-between">
          <SidepanelHeader>Optimize {route.name}</SidepanelHeader>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center relative">
          <LoadingIcon fixed={false} />
        </div>
      ) : (
        <>
          <div className="p-5 overflow-y-scroll">
            <div className="grid grid-cols-2">
              <RouteCompare
                name="Optimized"
                color={newRouteColor}
                distance={comparison.route.drivingDistanceFormatted}
                time={comparison.route.drivingDurationSeconds}
                selected={showOptimized === true}
                updateShowOptimized={() => onChangeRoute(true)}
              />
              <RouteCompare
                name="Original"
                color={originalRouteColor}
                distance={comparison.oldRoute.drivingDistanceFormatted}
                time={comparison.oldRoute.drivingDurationSeconds}
                selected={showOptimized === false}
                updateShowOptimized={() => onChangeRoute(false)}
              />
            </div>
            <div className="border-slate-400 border-l-2 border-r-2 border-b-2 rounded-b bg-gray-50">
              <div className="p-3 overflow-y-scroll">
                <ul role="list">
                  {selectedRoute &&
                    selectedRoute.jobs &&
                    selectedRoute.jobs.map((job, index) => {
                      const colorName = showOptimized
                        ? newRouteColor
                        : originalRouteColor;

                      let bgColor = colors[colorName].bg;
                      let hasChanged = false;
                      if (showOptimized) {
                        bgColor = colors[colorName].bgHover;
                        if (
                          comparison.route.jobs[index].name !=
                          comparison.oldRoute.jobs[index].name
                        ) {
                          hasChanged = true;
                          bgColor = colors[colorName].bg;
                        }
                      }

                      return (
                        <li
                          key={index}
                          className="py-0 min-h-8 group relative flex items-center w-full"
                        >
                          <span
                            className={`flex min-w-0 flex-col size-6 text-center rounded-full ${bgColor} text-white text-sm pt-0.5`}
                          >
                            {index + 1}
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col items-center">
                            <span className="text-sm font-medium text-gray-500">
                              {hasChanged ? <em>{job.name}</em> : job.name}
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            <p className="mt-6 text-sm">
              <span className="font-semibold">Beta Note:</span>
              &nbsp;Our optimizer currently only handles 24 points and hits
              snags if your route loops.{" "}
              <a className="underline" href="mailto:support@mysnowscape.com">
                Send us feedback
              </a>{" "}
              as you use it so we can improve it further!
            </p>
          </div>

          {showOptimized ? (
            <RoutePath
              path={comparison.route.drivingPath}
              color={newRouteColorCompleted}
            />
          ) : (
            <RoutePath
              path={comparison.oldRoute.drivingPath}
              color={originalRouteColorCompleted}
            />
          )}

          {routeLocations.start.location ? (
            <StartEndMapMarker
              type="start"
              position={routeLocations.start.location}
            />
          ) : null}
          {routeLocations.end.location ? (
            <StartEndMapMarker
              type="end"
              position={routeLocations.end.location}
            />
          ) : null}

          {selectedRoute &&
            selectedRoute.jobs &&
            selectedRoute.jobs.map((job, index) => (
              <MapMarker
                key={job.id}
                color={showOptimized ? newRouteColor : originalRouteColor}
                number={index + 1}
                position={{ lat: job.location.lat, lng: job.location.lng }}
              />
            ))}
        </>
      )}

      <SidepanelFooter>
        <SubtleButton
          onClick={optimizeRoute}
          type="submit"
          testId="accept-changes-button"
          buttonRole="primary"
          className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
        >
          Accept Changes
        </SubtleButton>

        <SubtleButton
          onClick={onCancel}
          type="submit"
          testId="close-button"
          buttonRole="secondary"
          className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
        >
          Keep Original
        </SubtleButton>
      </SidepanelFooter>
    </div>
  );
};

export default OptimizePane;
