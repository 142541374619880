import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Services
import { RouteContext } from "../RouteContext";
import OptimizePane from "./Optimize/Pane";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Sidepanel from "../../../../../components/Maps/ActiveRoutes/ui/sidepanel";
import SidepanelHeader from "../../../../../components/Maps/ActiveRoutes/ui/sidepanel-header";
import SidepanelFooter from "../SidepanelFooter";
import SubtleButton from "../../../../../components/Buttons/SubtleButton";

const OptimizeRoute = () => {
  let { routeId } = useParams();
  const navigate = useNavigate();

  // pull jobs for this route of route context
  const { jobs, route, routeLocations, setOptimizedRoute } =
    useContext(RouteContext);

  const [loading, setLoading] = useState(false);
  const [noOptimizing, setNoOptimizing] = useState(false);

  const closeRoute = () => {
    navigate("/admin/route/" + routeId, {
      preventScrollReset: true,
    });
  };
  const previewRoute = () => {
    navigate("/admin/route/" + routeId + "/preview", {
      preventScrollReset: true,
    });
  };

  const optimizeRoute = (drivingPath, optimizedJobs) => {
    setOptimizedRoute(drivingPath, optimizedJobs);
    closeRoute();
  };

  const [comparison, setComparison] = useState({
    route: {
      drivingDistanceFormatted: "",
      drivingDistanceMeters: 0,
      drivingDurationSeconds: 0,
      drivingPath: "",
      jobs: [],
    },
    oldRoute: {
      drivingDistanceFormatted: "",
      drivingDistanceMeters: 0,
      drivingDurationSeconds: 0,
      drivingPath: "",
      jobs: [],
    },
  });

  useEffect(() => {
    if (jobs.length > 24) {
      setNoOptimizing(true);
      return;
    }

    setLoading(true);
    axios
      .post(`route/calculate-path?optimize=1&optimize_calc_old_path=1`, {
        start: routeLocations.start.location,
        end: routeLocations.end.location,
        jobIds: jobs.map((j) => j._id),
      })
      .then((resp) => {
        let payload = {
          route: {
            drivingDistanceFormatted: resp.data.route.drivingDistanceFormatted,
            drivingDistanceMeters: resp.data.route.drivingDistanceMeters,
            drivingDurationSeconds: resp.data.route.drivingDurationSeconds,
            drivingPath: resp.data.route.drivingPath,
            jobs: resp.data.route.jobs,
          },
          oldRoute: {
            drivingDistanceFormatted:
              resp.data.oldRoute.drivingDistanceFormatted,
            drivingDistanceMeters: resp.data.oldRoute.drivingDistanceMeters,
            drivingDurationSeconds: resp.data.oldRoute.drivingDurationSeconds,
            drivingPath: resp.data.oldRoute.drivingPath,
            jobs: resp.data.oldRoute.jobs,
          },
        };
        setComparison(payload);
      })
      .finally(() => setLoading(false));
  }, [jobs, routeId, routeLocations]);

  return (
    <Sidepanel
      panelStyle={{ top: "1.5rem", right: "1.5rem", zIndex: 20 }}
      style={{ width: 300, height: 600, background: "#fff" }}
    >
      {!noOptimizing ? (
        <OptimizePane
          isLoading={loading}
          route={route}
          routeLocations={routeLocations}
          comparison={comparison}
          onCancel={closeRoute}
          onOptimize={optimizeRoute}
        />
      ) : (
        <div className="h-full grid grid-rows-[55px_auto_77px]">
          <div className="px-5 py-4 border-b border-gray-200">
            <div className="flex items-start justify-between">
              <SidepanelHeader>Too many properties</SidepanelHeader>
            </div>
          </div>
          <div className="p-5 overflow-y-scroll">
            Our optimizer currently only handles 25 points. For now, please
            optimize visually using the map and Preview screen. <br />
            <br />
            Please{" "}
            <a className="underline" href="mailto:support@mysnowscape.com">
              let us know
            </a>{" "}
            if you need the ability to optimize more than 25 properties on a
            route.
          </div>
          <SidepanelFooter>
            <SubtleButton
              onClick={previewRoute}
              type="submit"
              testId="accept-changes-button"
              buttonRole="primary"
              className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
            >
              Preview
            </SubtleButton>

            <SubtleButton
              onClick={closeRoute}
              type="submit"
              testId="close-button"
              buttonRole="secondary"
              className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
            >
              Close
            </SubtleButton>
          </SidepanelFooter>
        </div>
      )}
    </Sidepanel>
  );
};

export default OptimizeRoute;
