import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Sidepanel from "./ui/sidepanel";
import SidepanelHeader from "./ui/sidepanel-header";
import { useQuery } from "@tanstack/react-query";
import LoadingIcon from "../../Loaders/LoadingIcon";
import { formatAddress } from "../../../pages/Admin/Routes/RoutePage/Routes/utils";
import format from "date-fns/format";

const ActiveRoutesRoutePropertyDetail = ({ currentUser }) => {
  const activeRoutesQuery = useQuery({
    queryKey: ["company", currentUser.currentCompanyId, "activeRoutes-new"],
    queryFn: async () =>
      (
        await axios.get(
          `activeRoute/company-new/${currentUser.currentCompanyId}?take=10000`,
        )
      ).data,
  });

  let { routeId, propertyId } = useParams();
  let navigate = useNavigate();

  const loading = activeRoutesQuery.isLoading;

  if (loading) return <LoadingIcon />;

  const activeRoutes = activeRoutesQuery.data;

  let data = activeRoutes.find((route) => route._id == routeId);
  if (!data) {
    return <p className="pt-2 text-base">No route was found</p>;
  }

  let property = data.route?.jobs.find(
    (property) => property._id === propertyId,
  );
  if (!property) {
    return <p className="pt-2 text-base">No property was found</p>;
  }

  const lastServicedDate =
    property.jobHistories && property.jobHistories.length > 0
      ? new Date(
          property.jobHistories[property.jobHistories.length - 1].endTimeUTC,
        )
      : "";

  return (
    <Sidepanel
      panelStyle={{ top: "4.5rem", right: "4.5rem", zIndex: 20 }}
      style={{ width: 300, height: 600, background: "#f6f6f6" }}
    >
      <div className="px-5 py-4 border-b border-gray-200">
        <div className="flex items-end justify-between">
          <SidepanelHeader
            onClose={(e) => {
              e.preventDefault();
              navigate(`/admin/active-routes/main/${data._id}`);
            }}
          >
            {property.name}
          </SidepanelHeader>
        </div>
      </div>
      <div className="px-6 py-4 pb-4">
        <p className="text-sm">{formatAddress(property.address)}</p>
        {property.jobHistories?.length > 0 ? (
          <>
            <h3 className="mt-6 mb-2 text-gray-600 font-bold">
              Services performed
            </h3>
            {property.jobHistories.map((history, index) => (
              <>
                <ul key={index} className="pb-2 text-base">
                  {history.servicesPerformed
                    .filter((service) => service.checked)
                    .map((service, key) => (
                      <li key={key}>
                        {service.label}
                        {service.options ? (
                          <>
                            <ul
                              role="list"
                              className="pl-5 pt-2 pb-3 space-y-1 list-disc marker:text-sky-800"
                            >
                              {service.options
                                // .filter((option) => option.checked)
                                .map((option, key) => (
                                  <li key={key}>{option.label}</li>
                                ))}
                            </ul>
                          </>
                        ) : null}
                      </li>
                    ))}
                </ul>
              </>
            ))}
          </>
        ) : null}
        {lastServicedDate ? (
          <p className="pt-2 text-sm text-gray-500">
            Last serviced:{" "}
            {property.status === "current"
              ? "In Progress"
              : format(lastServicedDate, "MMM d, yyyy h:mm bbb")}
          </p>
        ) : null}
      </div>
    </Sidepanel>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesRoutePropertyDetail);
