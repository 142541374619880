import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosJobs = {
  createNewJob: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("job/add", data);
      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  deleteJob: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.delete("job/" + data.jobId + "/delete", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllJobs: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "job/company/" + data.companyId + "?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  filterJobs: async (
    companyId,
    filter = {},
    searchTerm = null,
    skip = 0,
    take = 30,
    abortSignal = null,
  ) => {
    try {
      return (
        await axios.get(`job/company/${companyId}/`, {
          params: {
            filter: filter,
            search: searchTerm,
            skip,
            take,
          },
          signal: abortSignal,
        })
      ).data;
    } catch (err) {
      errors.report(err);
      throw err;
    }
  },
  loadCityOptions: async (companyId, abortSignal = null) => {
    try {
      return (
        await axios.get(`job/company/${companyId}/cities`, {
          signal: abortSignal,
        })
      ).data;
    } catch (err) {
      errors.report(err);
      throw err;
    }
  },
  loadOperatorOptions: async (companyId, abortSignal = null) => {
    try {
      return (
        await axios.get(`company/${companyId}/users?take=1000`, {
          signal: abortSignal,
        })
      ).data.map((u) => {
        return { label: `${u.firstName} ${u.lastName}`, value: u.uid };
      });
    } catch (err) {
      errors.report(err);
      throw err;
    }
  },
  getAllJobsForSeason: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "job/company/" +
          data.companyId +
          "?filter[season]=" +
          data.season +
          "&take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getJobById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("job/" + data.jobId);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  updateJob: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("job/update", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  bulkUploadExcel: async (formData) => {
    // special case errors handled in BulkImportJobs.jsx file
    const res = await axios.post("job/bulkimport", formData);
    return res.data;
  },
};

export default axiosJobs;
