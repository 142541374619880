import React from "react";

import Polyline from "./shared/polyline";

const RoutePath = (props) => {
  const handleClick = (e) => {
    if (props.pathClick) {
      props.pathClick(e);
    }
  };

  const makeOpaque =
    props.isActive === undefined || props.isActive === true ? 1 : 0.5;

  const zIndex =
    props.isActive === undefined || props.isActive === true ? 10 : 5;

  return (
    <>
      {props.path ? (
        <Polyline
          onClick={handleClick}
          strokeWeight={5}
          strokeColor={props.color}
          encodedPath={props.path}
          strokeOpacity={makeOpaque}
          zIndex={zIndex}
        />
      ) : null}
    </>
  );
};

export default RoutePath;
