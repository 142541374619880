import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useFeatureFlag, User } from "configcat-react";

// Components
// import FilterBar from '../../../components/Filters/FilterBar';
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";
import NotificationModal from "../../../components/Modals/NotificationModal";

// Services
import axiosRoutes from "../../../services/axios/routes";
import axios from "axios";
import EditProperties from "./RoutePage/EditProperties";
import AddPropertyWrapper from "./RoutePage/AddPropertyWrapper";
import ActiveRoutesBaseLayer from "../../../components/Maps/ActiveRoutes/BaseLayer";
import { RouteContext } from "./RoutePage/RouteContext";
import ColorPicker from "../../../components/Inputs/ColorPicker";
import axiosCompanyLocations from "../../../services/axios/companyLocations";
import { ObjectID } from "bson";

// our dnd-kit currently has a hard-coded assumption that all
// sortable objects have a .id property that can be used for
// rearranging objects. ours don't. we setup both a job.id and make
// sure it handles duplicate jobs so they can be re-arranged without causing
// React rendering problems
export const remapWithSortableId = (jobs) => {
  let jobIdCounter = {};
  let mappedJobs = jobs.map((job) => {
    jobIdCounter[job._id] =
      jobIdCounter[job._id] !== undefined ? jobIdCounter[job._id] + 1 : 0;

    return {
      ...job,
      ...{
        id: job._id + "_" + jobIdCounter[job._id],
      },
    };
  });

  return mappedJobs;
};

const NULL_START_END_PAYLOAD = {
  id: null,
  location: null,
  name: null,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UpdateRoutePage = (props) => {
  const navigate = useNavigate();
  let { routeId } = useParams();
  const isNew = routeId === "new";
  let [searchParams] = useSearchParams();

  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayNotificationModal, setDisplayNotificationModal] =
    useState(false);
  const [displayDeactivateModal, setDisplayDeactivateModal] = useState(false);
  const [headerLabel, setHeaderLabel] = useState("Route");
  const [loading, setLoading] = useState(!isNew);
  const [jobs, setJobs] = useState([]);
  const [routeChanged, setRouteChanged] = useState(false);

  const [companyLocations, setCompanyLocations] = useState([]);

  const [routeLocations, setStartEnd] = useState({
    start: NULL_START_END_PAYLOAD,
    end: NULL_START_END_PAYLOAD,
  });

  const [route, setRoute] = useState({
    color: "emerald",
    season: isNew ? searchParams.get("season") : undefined,
  });
  const [showAddPropertyWindow, setShowAddPropertyWindow] = useState(false);
  const [routeColor, setRouteColor] = useState(route.color);
  const [activeTab, setActiveTab] = useState("map");

  useEffect(() => {
    if (!isNew) {
      setLoading(true);
      axios
        .get(`route/${routeId}?include=jobs`)
        .then((resp) => {
          setRoute(resp.data);
          setRouteColor(
            resp.data.color == "undefined" ? "emerald" : resp.data.color,
          );
          setStartEnd({
            start: resp.data.start ?? NULL_START_END_PAYLOAD,
            end: resp.data.end ?? NULL_START_END_PAYLOAD,
          });
          setHeaderLabel(resp.data.name);
          setJobs(remapWithSortableId(resp.data.jobs));
          setRouteChanged(false);
        })
        .finally(() => setLoading(false));
    }
  }, [isNew, routeId]);

  useEffect(() => {
    axiosCompanyLocations.getAllLocations(
      {
        companyId: props.currentUser.currentCompanyId,
      },
      (data) => setCompanyLocations(data.locations),
    );
  }, [props.currentUser.currentCompanyId]);

  const handleSaveRoute = () => {
    setLoading(true);

    // jobIds here sets our true order for jobs on the backend
    let jobIds = jobs.map((j) => j._id);

    // right now, as long as we only send back jobIds, we're fine
    // if we ever think about sending job data back raw from here
    // we need to strip out some of our book-keeping items from the
    // jobs payload (job.id and job.isNew)
    let drivingDirections = routeChanged
      ? {}
      : {
          drivingDistanceFormatted: route.drivingDistanceFormatted,
          drivingDistanceMeters: route.drivingDistanceMeters,
          drivingDurationSeconds: route.drivingDurationSeconds,
          drivingPath: route.drivingPath,
        };
    const payload = {
      ...{
        companyId: props.currentUser.currentCompanyId,
        details: route.details,
        name: route.name,
        color: routeColor,
        start: routeLocations.start,
        end: routeLocations.end,
        jobIds,
      },
      ...drivingDirections,
    };
    if (isNew) {
      axiosRoutes.createNewRoute(
        {
          ...{
            season: route.season,
          },
          ...payload,
        },
        () => {
          setLoading(false);
          navigate("/admin/route");
        },
      );
    } else {
      axiosRoutes.updateRoute(
        {
          ...payload,
          ...{
            _id: routeId,
          },
        },
        () => {
          setLoading(false);
          navigate("/admin/route");
        },
      );
    }
  };

  const handleDeleteRoute = () => {
    axiosRoutes.deleteRoute(
      { routeId: route._id },
      () => {
        setDisplayNotificationModal(true);
      },
      (err) => {
        alert(
          "Oops. Something went wrong while deleting this route. Please try again.",
        );
        console.log("Delete Route Error: ", err);
      },
    );
    return;
  };

  const handleUpdateRouteIsActive = () => {
    axiosRoutes.updateRoute(
      {
        _id: route._id,
        isActive: !route.isActive,
      },
      () => {
        setRoute({ ...route, isActive: !route.isActive });
        setLoading(false);
        setDisplayDeactivateModal(false);
      },
      (err) => {
        alert(
          "Oops. Something went wrong while deactivating this route. Please try again.",
        );
        console.log("Deactivate Route Error: ", err);
        setLoading(false);
      },
    );
  };

  const saveCompanyLocation = (name, type, location) => {
    let savedLocation = {
      companyId: props.currentUser.currentCompanyId,
      name,
      location: location.location,
      address: location.address,
      _id: new ObjectID().toString(),
    };
    axiosCompanyLocations.addLocation(savedLocation, () => {
      delete savedLocation.companyId;
      setCompanyLocations([...companyLocations, ...[savedLocation]]);
      setRouteLocation(type, savedLocation);
    });
  };
  const addJobs = (newJobs) => {
    // mutating these jobs with a property that the backend doesn't know about is a slight code smell
    newJobs.forEach((job) => (job.isNew = true));
    setJobsAndDirty([...jobs, ...newJobs]);
  };

  const setJobsAndDirty = (jobs) => {
    setRouteChanged(true);
    setJobs(remapWithSortableId(jobs));
  };

  const setOptimizedRoute = (optimizedRoute) => {
    let resortedJobs = optimizedRoute.jobs;
    setRouteChanged(false);
    setRoute({
      ...route,
      ...optimizedRoute,
    });

    const updatedJobIdOrder = remapWithSortableId(resortedJobs).map(
      (j) => j.id,
    );

    jobs.sort(
      (a, b) =>
        updatedJobIdOrder.indexOf(a.id) - updatedJobIdOrder.indexOf(b.id),
    );

    setJobs(jobs);
  };

  const setPreviewedRoute = (previewedRoute) => {
    setRouteChanged(false);
    setRoute({
      ...route,
      ...previewedRoute,
    });
  };

  // address can be a payload or null
  const setRouteLocation = (type, location) => {
    let newLocation = {};
    newLocation[type] = location ?? NULL_START_END_PAYLOAD;
    setStartEnd({
      ...routeLocations,
      ...newLocation,
    });
    setRouteChanged(true);
  };

  let buttons = [];
  if (!isNew) {
    buttons.push({
      label: "Delete",
      color: "red",
      onClick: () => setDisplayDeleteModal(true),
    });
    buttons.push({
      label: route.isActive ? "Make Inactive" : "Activate",
      color: "blue",
      onClick: () => setDisplayDeactivateModal(true),
    });
  }
  buttons.push({
    label: "Save",
    color: "green",
    onClick: handleSaveRoute,
    disabled: !route.name || jobs.length < 1,
  });

  let { value: mapFlag } = useFeatureFlag(
    "mappingWeb",
    false,
    new User(props.currentUser._id, null, null, {
      companyId: props.currentUser.currentCompanyId,
    }),
  );
  const tabs = [
    { id: "map", name: "Route Map" },
    { id: "list", name: "Route List" },
  ];

  return (
    <>
      <div className="flex flex-col items-center flex-1 min-h-full">
        <HeaderBar title={headerLabel} buttons={buttons} />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex w-full flex-1">
            <div
              className={`flex-1 flex-col w-full p-10 items-center overflow-y-scroll ${showAddPropertyWindow ? "hidden lg:flex" : "flex"}`}
            >
              <LabeledTextboxInput
                label="Name"
                placeholder="Name your route..."
                setValue={(val) => {
                  setRoute({ ...route, name: val });
                }}
                value={route.name}
              />
              <LabeledTextboxInput
                label="Description"
                placeholder="Tell us more about this route..."
                setValue={(val) => {
                  setRoute({ ...route, details: val });
                }}
                value={route.details}
              />
              {mapFlag ? (
                <>
                  <ColorPicker
                    label="Route Color"
                    setValue={(val) => {
                      route.color = val;
                      setRouteColor(val);
                    }}
                    value={routeColor}
                  />
                  <div className="self-start mb-4">
                    <div className="grid grid-cols-1 sm:hidden">
                      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                      <select
                        defaultValue={
                          tabs.find((tab) => tab.id === activeTab).name
                        }
                        aria-label="Select a tab"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                      >
                        {tabs.map((tab) => (
                          <option key={tab.id}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav aria-label="Tabs" className="flex space-x-4">
                        {tabs.map((tab, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setActiveTab(tab.id);
                            }}
                            aria-current={
                              tab.id === activeTab ? "page" : undefined
                            }
                            className={classNames(
                              tab.id === activeTab
                                ? "bg-gray-300 text-gray-700"
                                : "text-gray-500 hover:text-gray-700",
                              "rounded-md px-3 py-2 text-sm font-medium",
                            )}
                          >
                            {tab.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                  {/* TODO: shift away from active routes base layer component? */}
                  {activeTab === "map" ? (
                    <RouteContext.Provider
                      value={{
                        companyLocations,
                        route,
                        routeColor,
                        jobs,
                        routeChanged,
                        routeLocations,
                        addJobs,
                        saveNewLocation: saveCompanyLocation,
                        setJobs: setJobsAndDirty,
                        setOptimizedRoute: setOptimizedRoute,
                        setPreviewedRoute,
                        setRouteLocation,
                      }}
                    >
                      <div
                        className="relative"
                        style={{ width: "100%", height: "640px" }}
                      >
                        <ActiveRoutesBaseLayer>
                          <Outlet />
                        </ActiveRoutesBaseLayer>
                      </div>
                    </RouteContext.Provider>
                  ) : (
                    <EditProperties
                      isNew={isNew}
                      jobs={jobs}
                      updateOrder={setJobsAndDirty}
                      setJobs={setJobs}
                      showAddPropertyWindow={showAddPropertyWindow}
                      setShowAddPropertyWindow={setShowAddPropertyWindow}
                    />
                  )}
                </>
              ) : (
                <EditProperties
                  isNew={isNew}
                  jobs={jobs}
                  updateOrder={setJobsAndDirty}
                  setJobs={setJobs}
                  showAddPropertyWindow={showAddPropertyWindow}
                  setShowAddPropertyWindow={setShowAddPropertyWindow}
                />
              )}

              <div className="flex items-start w-full mt-10">
                <StartARouteCard></StartARouteCard>
              </div>
            </div>
            {showAddPropertyWindow ? (
              <AddPropertyWrapper
                onCancel={() => setShowAddPropertyWindow(false)}
                active={showAddPropertyWindow}
                companyId={props.currentUser.currentCompanyId}
                season={route.season}
                setShowProperty={setShowAddPropertyWindow}
                existingJobs={jobs}
                concatJobs={(newJobs) => {
                  setJobs([...jobs, ...newJobs]);
                }}
              />
            ) : null}
          </div>
        )}
      </div>
      <MultipleChoiceModal
        open={displayDeleteModal}
        options={[
          {
            color: "red",
            label: "Delete Permanently",
            onClick: () => {
              setDisplayDeleteModal(false);
              handleDeleteRoute();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeleteModal(false),
          },
        ]}
        subtitle="Are you sure you want to delete this route? Warning, this is a permanent action."
        title="Delete"
      />
      <MultipleChoiceModal
        open={displayDeactivateModal}
        options={[
          {
            color: "green",
            label: route.isActive ? "Make Inactive" : "Activate Route",
            onClick: () => {
              setDisplayDeactivateModal(false);
              handleUpdateRouteIsActive();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeactivateModal(false),
          },
        ]}
        subtitle={
          route.isActive
            ? "If you deactivate this route employees will no longer be able to perform this route until you re-activate it. All data will be kept and you can re-activate this route at any time."
            : "Are you sure you want to activate this route? You will be able to see this route at the top of the list of routes while using the admin web portal."
        }
        title={route.isActive ? "Make Inactive" : "Activate Route"}
      />

      <NotificationModal
        button={{
          color: "blue",
          label: "Okay",
          onClick: () => {
            // Close and go back
            setDisplayNotificationModal(false);
            navigate(-1);
          },
        }}
        open={displayNotificationModal}
        subtitle="Congratulations! This route has been deleted."
        title="Deleted"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(UpdateRoutePage);
export { UpdateRoutePage };
