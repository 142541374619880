import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosCompanyLocations = {
  addLocation: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.put(
        "company/" + data.companyId + "/locations",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllLocations: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("company/" + data.companyId + "/locations");

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  removeLocation: async (
    companyId,
    locationId,
    onSuccess = noop,
    onFail = noop,
  ) => {
    try {
      const res = await axios.delete(
        "company/" + companyId + "/locations/" + locationId,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
};

export default axiosCompanyLocations;
