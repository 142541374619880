import React from "react";

// Resources
import { DragDropIcon } from "../../pages/Admin/Routes/RoutePage/UI/DragDropIcon";
import { useSortable } from "@dnd-kit/react/sortable";
import { RestrictToVerticalAxis } from "@dnd-kit/abstract/modifiers";

const DragDropCardCardWrapper = (props) => {
  const { ref } = useSortable({
    id: props.jobId,
    index: props.index,
    modifiers: [RestrictToVerticalAxis],
  });
  return (
    <div ref={ref} className="w-full flex flex-row">
      <div className="flex-1">{props.children}</div>
      <div className="flex ml-2 flex-col justify-center mb-4">
        <DragDropIcon />
      </div>
    </div>
  );
};

export default DragDropCardCardWrapper;
