import axios from "axios";
import React from "react";
import { connect } from "react-redux";

// Components
import ActiveRouteCard from "../../../../components/Cards/ActiveRouteCard";
import LoadingIcon from "../../../../components/Loaders/LoadingIcon";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ActiveRoutesIndexMap from "../../../../components/Maps/ActiveRoutes/IndexMap";

const ActiveRoutesIndexPage = (props) => {
  const queryClient = useQueryClient();

  const activeRoutesQuery = useQuery({
    queryKey: [
      "company",
      props.currentUser.currentCompanyId,
      "activeRoutes-new",
    ],
    queryFn: async () =>
      (
        await axios.get(
          `activeRoute/company-new/${props.currentUser.currentCompanyId}?take=10000`,
        )
      ).data,
  });

  const loading = activeRoutesQuery.isLoading;
  const activeRoutes = activeRoutesQuery.data;

  const refreshData = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "company",
        props.currentUser.currentCompanyId,
        "activeRoutes-new",
      ],
    });
  };

  const handleDelete = async (e, uid) => {
    e.preventDefault();
    e.stopPropagation();

    // Display an alert asking if the user is sure they want to delete the route
    if (
      window.confirm(
        "Are you sure you want to discontinue this route? The active route will be discontinued, but no data will be lost.",
      )
    ) {
      await axios.delete(`activeRoute/operator/${uid}/delete`);
      refreshData();
    }
  };

  const activeRoutesOnMap = props.showList == false;
  if (loading) return <LoadingIcon />;

  return (
    <>
      {activeRoutesOnMap ? (
        <ActiveRoutesIndexMap activeRoutes={activeRoutes} />
      ) : (
        <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
          <div className="w-full">
            {activeRoutes.map((route, key) => (
              <ActiveRouteCard
                route={route}
                key={key}
                onDelete={(e) => {
                  handleDelete(e, route.operator.uid);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesIndexPage);
