import { formatDuration, intervalToDuration } from "date-fns";

export const drivingDetails = (distanceFormatted, durationSeconds) => {
  const formattedDriveTime = formatDuration(
    intervalToDuration({ start: 0, end: durationSeconds * 1000 }),
    { format: ["hours", "minutes"] },
  );

  return `${distanceFormatted} / Drive in ${formattedDriveTime}`;
};

export const formatAddress = (address) =>
  address.streetLineOne +
  ", " +
  (address.streetLineTwo ? address.streetLineTwo + ", " : "") +
  address.city +
  ", " +
  address.state +
  " " +
  address.zip;
