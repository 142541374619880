import React from "react";
import { connect } from "react-redux";

// Components
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import { useQueryClient } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import ActiveRoutesBaseLayer from "../../../components/Maps/ActiveRoutes/BaseLayer";
import Index from "./ActiveRoutes/Index";
import { useFeatureFlag, User } from "configcat-react";
import { useState } from "react";
import { useEffect } from "react";

const ActiveRoutesPage = (props) => {
  const queryClient = useQueryClient();

  const refreshData = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "company",
        props.currentUser.currentCompanyId,
        "activeRoutes-new",
      ],
    });
  };

  let { value: mapFlag } = useFeatureFlag(
    "activeRouteMaps",
    false,
    new User(props.currentUser._id, null, null, {
      companyId: props.currentUser.currentCompanyId,
    }),
  );

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Active Routes">
        <RefreshTimer refreshData={refreshData} />
      </HeaderBar>
      {!mapFlag ? (
        <div className="items-start w-full px-10 pt-3">
          <p>
            See which routes and properties your staff are currently servicing.
          </p>
        </div>
      ) : null}

      {/* mapping occurs here */}
      {mapFlag ? (
        <div className="relative" style={{ width: "100%", height: "680px" }}>
          <ActiveRoutesBaseLayer>
            <Index showList={false} />
            <Outlet />
          </ActiveRoutesBaseLayer>
        </div>
      ) : null}

      <Index />

      <div className="flex items-start w-full px-10">
        <StartARouteCard></StartARouteCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesPage);

const RefreshTimer = ({ refreshData }) => {
  let [timeRemaining, setTimeRemaining] = useState(60);

  useEffect(() => {
    if (timeRemaining <= 0) {
      refreshData();
      setTimeRemaining(60);
    }
    setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
  });

  return (
    <>
      <span className="text-sm text-gray-600 italic mt-4">
        Next refresh: {timeRemaining}s
      </span>
    </>
  );
};
