import React from "react";

const Sidepanel = (props) => {
  let panelStyle = props.panelStyle ?? {};
  let style = props.style ?? { width: 300, height: 600, background: "white" };
  return (
    <div
      className="absolute z-10 top-6 right-6"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
      style={panelStyle}
    >
      <div className="overflow-hidden shadow-xl rounded-lg">
        <div className="pointer-events-none flex max-w-full">
          {/* Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full" */}
          <div className="pointer-events-auto max-w-md" style={style}>
            <div className="h-full overflow-y-scroll relative">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidepanel;
