import { deepmerge } from "deepmerge-ts";
import { useEffect, useState } from "react";
import { REPORT_OPTIONS } from "@snowscape/snow-lib";
let reportSettingsDefault = {};
for (const key in REPORT_OPTIONS) {
  reportSettingsDefault[key] = REPORT_OPTIONS[key].default;
}
const chooseServiceOptions = (companyServices, jobServices) => {
  if (!jobServices || jobServices.length === 0) {
    return companyServices;
  }
  return jobServices;
};

const defaultSeasonValues = () => {
  return {
    instructions: "",
    report: {
      emailList: [],
      reportSettings: {
        ...reportSettingsDefault,
      },
    },
    sitemaps: [],
    serviceOptions: [],
    requiredImageCount: "",
    requireNotes: true,
    requireReportEmails: true,
    requireSiteMap: true,
  };
};

export function useJobState() {
  const [jobState, setRawJobState] = useState({
    address: {
      city: "",
      state: "",
      streetLineOne: "",
      streetLineTwo: "",
      zip: "",
    },
    enableLandscape: false,
    enableSnow: false,
    name: "",

    landscape: defaultSeasonValues(),
    snow: defaultSeasonValues(),
  });

  const [isFormInvalid, setIsFormInvalid] = useState(true);

  // Checks that the needed fields are completed before enabling the "Create" button
  useEffect(() => {
    jobState.snow.sitemaps = jobState.snow.sitemaps || [];
    jobState.landscape.sitemaps = jobState.landscape.sitemaps || [];
    jobState.snow.report = jobState.snow.report || {
      emailList: [],
      reportSettings: {},
    };
    jobState.landscape.report = jobState.landscape.report || {
      emailList: [],
      reportSettings: {},
    };

    // Checks that the general form fields are filled in
    const isJobFormComplete =
      jobState.name &&
      jobState.address.streetLineOne &&
      jobState.address.city &&
      jobState.address.state &&
      jobState.address.zip;

    // If Enable Snow Season is clicked, checks to make sure all of the fields are filled in
    const isSnowSeasonComplete =
      !jobState.enableSnow ||
      (jobState.snow.instructions &&
        (jobState.snow.sitemaps.length > 0 ||
          jobState.snow.requireSiteMap === false) &&
        (jobState.snow.report.emailList.length > 0 ||
          jobState.snow.requireReportEmails === false) &&
        jobState.snow.requiredImageCount &&
        jobState.snow.serviceOptions.some((service) => service.checked));

    // If Enable Landscape Season is clicked, checks to make sure all of the fields are filled in
    const isLandscapeSeasonComplete =
      !jobState.enableLandscape ||
      (jobState.landscape.instructions &&
        (jobState.landscape.sitemaps.length > 0 ||
          jobState.landscape.requireSiteMap === false) &&
        (jobState.landscape.report.emailList.length > 0 ||
          jobState.landscape.requireReportEmails === false) &&
        jobState.landscape.requiredImageCount &&
        jobState.landscape.serviceOptions.some((service) => service.checked));

    setIsFormInvalid(
      !isJobFormComplete ||
        (jobState.enableSnow && !isSnowSeasonComplete) ||
        (jobState.enableLandscape && !isLandscapeSeasonComplete) ||
        (!jobState.enableSnow && !jobState.enableLandscape),
    );
  }, [jobState]);

  // Checks for missing elements and pushes them to the missingElementsArray
  const checkInternalMissingElements = (callback) => {
    const missingElementsArray = [];

    // Basic Property Form Elements
    if (!jobState.name) missingElementsArray.push("Job name");
    if (!jobState.address.streetLineOne)
      missingElementsArray.push("Street address");
    if (!jobState.address.city) missingElementsArray.push("City");
    if (!jobState.address.state) missingElementsArray.push("State");
    if (!jobState.address.zip) missingElementsArray.push("ZIP code");

    if (!jobState.enableLandscape && !jobState.enableSnow)
      missingElementsArray.push("Select Season(s)");

    // Snow Elements
    if (jobState.enableSnow) {
      if (!jobState.snow.instructions)
        missingElementsArray.push("Snow instructions");
      if (
        jobState.snow.sitemaps.length === 0 &&
        jobState.snow.requireSiteMap === true
      )
        missingElementsArray.push("Snow sitemaps");
      if (
        jobState.snow.report.emailList.length === 0 &&
        jobState.snow.requireReportEmails === true
      )
        missingElementsArray.push("Snow emails");
      if (!jobState.snow.requiredImageCount)
        missingElementsArray.push("Snow required image count");
      if (
        jobState.snow.serviceOptions.filter((option) => option.checked)
          .length === 0
      ) {
        missingElementsArray.push("Snow service options");
      }
    }

    // Landscape Elements
    if (jobState.enableLandscape) {
      if (!jobState.landscape.instructions)
        missingElementsArray.push("Landscape instructions");

      if (
        jobState.landscape.sitemaps.length === 0 &&
        jobState.landscape.requireSiteMap === true
      )
        missingElementsArray.push("Landscape sitemaps");
      if (
        jobState.landscape.report.emailList.length === 0 &&
        jobState.landscape.requireReportEmails === true
      )
        missingElementsArray.push("Landscape emails");
      if (!jobState.landscape.requiredImageCount)
        missingElementsArray.push("Landscape required image count");
      if (
        jobState.landscape.serviceOptions.filter((option) => option.checked)
          .length === 0
      ) {
        missingElementsArray.push("Landscape service options");
      }
    }

    // Display missing elements alert
    missingElementsArray.length > 0
      ? callback(missingElementsArray)
      : console.log("Button should be enabled, but is disabled");
  };

  const setJobState = (key, value) => {
    setRawJobState({ ...jobState, [key]: value });
  };
  const setAddress = (key, value) => {
    setJobState("address", { ...jobState.address, [key]: value });
  };

  // setup a curried function: https://medium.com/front-end-weekly/javascript-es6-curry-functions-with-practical-examples-6ba2ced003b1
  const setInputValue = (key) => (value) => setJobState(key, value);

  const setJobSeasonState = (season) => (key, value) => {
    setJobState(season, {
      ...jobState[season],
      [key]: value,
    });
  };
  const setSnowState = setJobSeasonState("snow");
  const setLandscapeState = setJobSeasonState("landscape");

  const setLandscapeReportValue = (key, value) =>
    setLandscapeState("report", { ...jobState.landscape.report, [key]: value });
  const setSnowReportValue = (key, value) =>
    setSnowState("report", { ...jobState.snow.report, [key]: value });

  const setSnowInputValue = (key) => (value) => setSnowState(key, value);
  const setLandscapeInputValue = (key) => (value) =>
    setLandscapeState(key, value);

  // toggle our state and ensure we have a good initial set of values
  // defined if needed
  const setLandscapeEnabled = (value) => {
    // our initial state may have been overriden by backend state that has nothing defined
    if (
      value &&
      (!jobState.landscape ||
        (Object.keys(jobState.landscape).length === 1 &&
          jobState.landscape.serviceOptions.length > 0))
    ) {
      // have to make sure our serviceOptions don't get stomped
      setRawJobState(
        deepmerge(jobState, {
          enableLandscape: value,
          landscape: defaultSeasonValues(),
        }),
      );
      return;
    }
    setJobState("enableLandscape", value);
  };
  const setSnowEnabled = (value) => {
    // our initial state may have been overriden by backend state that has nothing defined
    if (
      value &&
      (!jobState.snow ||
        (Object.keys(jobState.snow).length === 1 &&
          jobState.snow.serviceOptions.length > 0))
    ) {
      // have to make sure our serviceOptions don't get stomped
      setRawJobState(
        deepmerge(jobState, {
          enableSnow: value,
          snow: defaultSeasonValues(),
        }),
      );
      return;
    }
    setJobState("enableSnow", value);
  };

  // this method is responsible for building up only what
  // our backend needs to know about. Rather than start with our internal
  // state and take things out, I'm opting for a "build it manually" approach
  // to keep from having UI state leak into backend payloads
  const buildPayload = (companyId) => {
    let { landscape: landscapeState, snow: snowState } = jobState;
    return {
      // values still needed
      // _id
      // jobName
      address: jobState.address,
      companyId,
      name: jobState.name,
      landscape: !jobState.enableLandscape
        ? {}
        : {
            instructions: landscapeState.instructions,
            report: {
              emailList: landscapeState.report.emailList,
              reportSettings: landscapeState.report.reportSettings,
            },
            requiredImageCount: landscapeState.requiredImageCount.value,
            requireNotes: landscapeState.requireNotes ?? true,
            requireReportEmails: landscapeState.requireReportEmails ?? true,
            requireSiteMap: landscapeState.requireSiteMap ?? true,
            serviceOptions: landscapeState.serviceOptions,
            sitemaps: landscapeState.sitemaps,
          },
      snow: !jobState.enableSnow
        ? {}
        : {
            instructions: snowState.instructions,
            report: {
              emailList: snowState.report.emailList,
              reportSettings: snowState.report.reportSettings,
            },
            requiredImageCount: snowState.requiredImageCount.value,
            requireNotes: snowState.requireNotes ?? true,
            requireReportEmails: snowState.requireReportEmails ?? true,
            requireSiteMap: snowState.requireSiteMap ?? true,
            serviceOptions: snowState.serviceOptions,
            sitemaps: snowState.sitemaps,
          },
    };
  };

  // it'd be lovely to eliminate requiredImageOptions here somehow
  // long-term, not doing that now
  const setupStateFromPayload = (
    payload,
    companyServices,
    requiredImageOptions,
  ) => {
    let internalJobData = {
      ...payload,
      enableSnow: Object.keys(payload.snow).length > 0,
      enableLandscape: Object.keys(payload.landscape).length > 0,
    };

    // Setup Services
    internalJobData.landscape.serviceOptions = chooseServiceOptions(
      companyServices.landscape,
      internalJobData.landscape?.serviceOptions,
    );
    internalJobData.snow.serviceOptions = chooseServiceOptions(
      companyServices.snow,
      internalJobData.snow?.serviceOptions,
    );

    // Setup Dropdowns
    if (internalJobData.snow.requiredImageCount !== undefined) {
      internalJobData.snow.requiredImageCount = requiredImageOptions.find(
        (option) =>
          option.value === internalJobData.snow.requiredImageCount ||
          option.value === JSON.parse(internalJobData.snow?.requiredImageCount),
      );
    }
    if (internalJobData.landscape.requiredImageCount !== undefined) {
      internalJobData.landscape.requiredImageCount = requiredImageOptions.find(
        (option) =>
          option.value === internalJobData.landscape.requiredImageCount ||
          option.value ===
            JSON.parse(internalJobData.landscape?.requiredImageCount),
      );
    }

    setRawJobState(internalJobData);
  };

  return {
    checkInternalMissingElements,
    isFormInvalid,
    jobState,
    setJobState,
    setAddress,
    setInputValue,

    setLandscapeEnabled,
    setLandscapeInputValue,
    setLandscapeReportValue,
    setLandscapeState,

    setSnowEnabled,
    setSnowInputValue,
    setSnowReportValue,
    setSnowState,
    setRawJobState,

    // we may want to move this elsewhere long-term?
    buildPayload,
    setupStateFromPayload,
  };
}
