import React from "react";
import TrashRed from "../../resources/trash-can-red.svg";
import { colors } from "../Maps/ActiveRoutes/colors";
import { RoutesBubbles } from "../../pages/Admin/Routes/RoutePage/MapJobCard";

function JobCard(props) {
  return (
    <div
      className={`flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40 ${props.className}`}
      onClick={props.onClick}
      data-testid={props.testId ?? `job-card-${props.name ?? ""}`}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl font-bold font-snow-primary flex gap-3 items-center">
          {props.name || "No Property Name Given"}
        </div>
        <div className="font-light text-base mb-2">
          {props.address.streetLineOne +
            ", " +
            (props.address.streetLineTwo
              ? props.address.streetLineTwo + ", "
              : "") +
            props.address.city +
            ", " +
            props.address.state +
            " " +
            props.address.zip}
        </div>
        <div className="font-md">{props.instructions}</div>
        <RoutesBubbles routes={props.routes} />
      </div>
      {props.showDelete ? (
        <div
          className="flex w-16 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={(e) => {
            props.onRightIconClicked();
            // Don't run card pressed function
            e.stopPropagation();
          }}
        >
          <img className="w-7" src={TrashRed} alt="trash" />
        </div>
      ) : props.cardSequence ? (
        <div
          className="flex w-16 justify-end items-center text-lg font-bold text-snow-green"
          data-testid="card-sequence"
        >
          {props.cardSequence}
        </div>
      ) : null}
    </div>
  );
}

function RouteBubble({ route }) {
  let colorOptions = colors[route.color];
  return (
    <span
      className={`${colorOptions.text} rounded-md ${colorOptions.bgLight} px-2 py-1  text-xs font-medium ring-1 ring-inset ring-red-600/10`}
    >
      {route.name}
    </span>
  );
}

export default JobCard;
