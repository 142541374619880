import React from "react";

const SidepanelFooter = (props) => {
  return (
    <div className="absolute bottom-0 right-0 left-0 bg-white border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex justify-start space-x-3 footer-buttons">
        {props.children}
      </div>
    </div>
  );
};

export default SidepanelFooter;
