import React, { useState } from "react";

import axiosCompanyLocations from "../../../../services/axios/companyLocations";

const CompanyLocations = ({ companyId, initialLocations }) => {
  const [companyLocations, setCompanyLocations] = useState(initialLocations);

  const deleteLocation = (locationId) => {
    axiosCompanyLocations.removeLocation(companyId, locationId, () => {
      setCompanyLocations(
        companyLocations.filter((location) => location._id !== locationId),
      );
    });
  };

  return (
    <>
      {companyLocations.length > 0 ? (
        <div className="w-full text-snow-primary mb-5">
          <div className="text-sm font-bold mb-0.5 ">Company Locations</div>
          <div className="text-sm text-snow-primary font-light mb-1">
            Saved locations for start / end points for your company routes
          </div>
          <div className="w-1/3 rounded-md py-1.5 px-2.5">
            <ul>
              {companyLocations.map((location, index) => (
                <li key={index} className="grid grid-cols-[auto_40px]">
                  <div>
                    {location.name} -{" "}
                    <span className="text-xs italic">{location.address}</span>
                  </div>
                  <div className="text-xs items-start self-center">
                    <button
                      onClick={() => {
                        deleteLocation(location._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CompanyLocations;
