import React, { useEffect, useRef } from "react";
import { PlaceAutocompleteClassic } from "../../../../../../components/Maps/PlaceAutocompleteClassic";
import { useState } from "react";
import { StartEndAddress } from "./StartEndAddress";

export const LocationChooser = ({
  locations,
  routeLocations,
  type,
  onAddLocation,
  onChooseLocation,
  onSaveLocation,
}) => {
  let [showAddLocation, setShowAddLocation] = useState(false);

  const routeLocation =
    routeLocations && routeLocations[type] ? routeLocations[type] : null;

  const onChange = ({ target }) => {
    const value = target.value;

    if (value === "---") {
      setShowAddLocation(false);
      onChooseLocation(type, null);
    } else if (value === "enter-address") {
      onChooseLocation(type, null);
      setShowAddLocation(true);
    } else {
      let address = locations.find((address) => address._id == value); // not using === is important here
      onChooseLocation(type, address);
      setShowAddLocation(false);
    }
  };

  const onClearTmpAddress = () => {
    setShowAddLocation(false);
    onChooseLocation(type, null);
  };

  const onPlaceSelect = (place) => {
    if (place.name && place.geometry) {
      onAddLocation(type, place);
    }
  };

  const onSaveBookmark = (name) => {
    setShowAddLocation(false);
    onSaveLocation(name, type, routeLocation);
  };

  const showTmpAddress =
    !showAddLocation && !routeLocation._id && routeLocation.address;

  const selectValue = showAddLocation
    ? "enter-address"
    : routeLocation._id ?? "";

  const bookmarkBtnDisabled =
    showAddLocation && routeLocation?.location === null;

  return (
    <span>
      {showTmpAddress ? (
        <>
          <button
            className="text-left group hover:opacity-60 hover:cursor-pointer active:opacity-90"
            onClick={(e) => {
              e.stopPropagation();
              onClearTmpAddress();
            }}
          >
            <StartEndAddress
              name={routeLocation.name}
              address={routeLocation.address}
            />
            <div className="ml-1.5 mt-0 inline-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-4 fill-slate-500 group-hover:fill-slate-900"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
        </>
      ) : (
        <>
          <select onChange={onChange} value={selectValue}>
            <option value="---">Choose location</option>
            {locations.map((location, key) => (
              <option key={key} value={location._id}>
                {location.name}
              </option>
            ))}
            <option disabled>----</option>
            <option value="enter-address">Enter address</option>
          </select>
          {showAddLocation ? (
            <>
              <br />
              <div className="grid grid-cols-[auto_40px] mt-2 ml-1">
                <ChooseAddressOrSave
                  bookmarkBtnDisabled={bookmarkBtnDisabled}
                  onClearAddress={onClearTmpAddress}
                  onPlaceSelect={onPlaceSelect}
                  onSaveBookmark={onSaveBookmark}
                />
              </div>
            </>
          ) : null}
        </>
      )}
    </span>
  );
};

const ChooseAddressOrSave = ({
  bookmarkBtnDisabled,
  onClearAddress,
  onPlaceSelect,
  onSaveBookmark,
}) => {
  const [bookmarkName, setBookmarkName] = useState(false);
  const [shouldBookmark, setShouldBookmark] = useState(false);

  const bookmarkNameInputRef = useRef(null);

  useEffect(() => {
    if (shouldBookmark) {
      bookmarkNameInputRef.current.focus();
    }
  }, [shouldBookmark]);

  const handleBookmarkChange = (e) => {
    e.target.value === "" && bookmarkName.length !== 1
      ? onClearAddress()
      : setBookmarkName(e.target.value);
  };

  const startBookmarking = () => {
    setShouldBookmark(true);
  };

  const saveBookmark = () => {
    onSaveBookmark(bookmarkName);
  };

  return (
    <>
      <div>
        <div className="mr-1">
          {shouldBookmark ? (
            <>
              <input
                type="search"
                placeholder="Bookmark name"
                className="border border-gray-200 p-1 rounded"
                onChange={handleBookmarkChange}
                ref={bookmarkNameInputRef}
              />
            </>
          ) : (
            <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect} />
          )}
        </div>
      </div>
      <div className="mt-0.5 ml-1">
        {shouldBookmark ? (
          <div className="mt-0.5">
            <button
              onClick={saveBookmark}
              className="group rounded-full border border-gray-400 p-1 hover:bg-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-3 text-gray-600 group-hover:text-white"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : !bookmarkBtnDisabled ? (
          <button
            onClick={startBookmarking}
            className="p-1 rounded group hover:bg-gray-200 justify-center self-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 group-hover:fill-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
              />
            </svg>
          </button>
        ) : null}
      </div>
    </>
  );
};
