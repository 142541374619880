import React from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { colors } from "../../../../../components/Maps/ActiveRoutes/colors";

import "./marker.css";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

export const MapMarker = ({
  position,
  number,
  highlight,
  isNew,
  color,
  onClick,
  children,
}) => {
  const [notifyChanged, setNotifyChanged] = useState(false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setTimeout(() => {
      setNotifyChanged(false);
    }, 750);
    setNotifyChanged(true);
  }, [number]);

  const bgColor = highlight
    ? colors[color].bgHover
    : isNew
      ? `${colors[color].bg} is-new`
      : colors[color].bg;
  const highlighted = highlight ? "highlighted" : "";
  const notify = notifyChanged ? "notify-changed" : "";

  return (
    <AdvancedMarker
      zIndex={highlight ? 20 : 10}
      position={position}
      onClick={onClick}
    >
      <div
        className={`map-marker-custom-pin ${bgColor} ${highlighted} ${notify}`}
      >
        <div className={`map-marker-inner-circle ${bgColor}`}>
          {children ?? number}
        </div>
      </div>
    </AdvancedMarker>
  );
};

export const StartEndMapMarker = ({ type, position }) => (
  <MapMarker key={type} color="gray" position={position}>
    {type == "start" ? "S" : "E"}
  </MapMarker>
);
