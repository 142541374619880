import React from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
} from "@vis.gl/react-google-maps";

import { colors } from "../colors";
import { intervalToDuration } from "date-fns";

export const calculateCrewStatus = (createdAt) => {
  let duration = null;
  let crewStatus = !createdAt ? "inactive" : "active";

  if (crewStatus === "active") {
    duration = intervalToDuration({
      start: new Date(),
      end: new Date(createdAt),
    });
    if (duration.days >= 1 || duration.hours >= 1 || duration.minutes >= 30) {
      crewStatus = "out-of-contact";
    }
  }
  return [crewStatus, duration];
};

export const CrewPin = ({ position, color, status, onClick }) => {
  const crewActive = status === "active";

  const markerClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <AdvancedMarker
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
      onClick={markerClick}
      position={position}
    >
      <span
        className={`relative z-10 flex size-9 items-center justify-center rounded-full ${colors[color].bg} border-2`}
      >
        <InternalCrewIcon active={crewActive} />
      </span>
    </AdvancedMarker>
  );
};

export const CrewIcon = ({ color, enabled }) => {
  return (
    <span
      className={`inline-flex size-5 items-center justify-center rounded-full ${colors[color].bg}`}
    >
      <InternalCrewIcon active={enabled} isSmall={true} />
    </span>
  );
};

const InternalCrewIcon = ({ active, isSmall }) => {
  const size = isSmall ? "size-5" : "size-8";

  return (
    <>
      {active ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className={size}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className={size}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
          />
        </svg>
      )}
    </>
  );
};
