import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useMapPathZoom } from "./use-map-zoom";
import Sidepanel from "./ui/sidepanel";
import SidepanelHeader from "./ui/sidepanel-header";
import { calculateCrewStatus, CrewIcon, CrewPin } from "./ui/crew";
import {
  CompleteCheck,
  RouteDetailsProgressBar,
} from "./RouteDetails/Progress";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingIcon from "../../Loaders/LoadingIcon";
import { StartEndMapMarker } from "../../../pages/Admin/Routes/RoutePage/Map/Marker";
import { colors } from "./colors";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

const ActiveRoutesRouteDetail = ({ currentUser }) => {
  const activeRoutesQuery = useQuery({
    queryKey: ["company", currentUser.currentCompanyId, "activeRoutes-new"],
    queryFn: async () =>
      (
        await axios.get(
          `activeRoute/company-new/${currentUser.currentCompanyId}?take=10000`,
        )
      ).data,
  });

  const queryClient = useQueryClient();

  let { routeId, propertyId } = useParams();

  let activePropertyId = propertyId;
  let navigate = useNavigate();

  const loading = activeRoutesQuery.isLoading;
  const activeRoutes = activeRoutesQuery.data;

  if (loading) return <LoadingIcon />;

  let data = activeRoutes.find((route) => route._id == routeId);

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Display an alert asking if the user is sure they want to delete the route
    if (
      window.confirm(
        "Are you sure you want to discontinue this route? The active route will be discontinued, but no data will be lost.",
      )
    ) {
      await axios.delete(`activeRoute/operator/${data.operator.uid}/delete`);
      queryClient.invalidateQueries({
        queryKey: ["company", currentUser.currentCompanyId, "activeRoutes-new"],
      });
      navigate(`/admin/active-routes/main`);
    }
  };

  useMapPathZoom(
    [data.route.drivingPath],
    activeRoutes.reduce((acc, route) => {
      acc.push(route.route.drivingPath);
      return acc;
    }, []),
  );

  if (!data) {
    return <p className="pt-2 text-base">No route was found</p>;
  }

  const clickProperty = (newPropertyId) => {
    if (newPropertyId !== activePropertyId) {
      navigate(`/admin/active-routes/main/${data._id}/${newPropertyId}`);
    } else {
      navigate(`/admin/active-routes/main/${data._id}`);
    }
  };

  const routeColor = data.route.color ?? "emerald";

  const [crewStatus, statusDuration] = calculateCrewStatus(
    data.operator?.lastKnownLocation?.createdAt,
  );
  let foreman = data.operator?.firstName + " " + data.operator?.lastName;

  let statusUpdated = "";
  if (statusDuration?.hours > 0) {
    statusUpdated = "over an hour";
  } else if (statusDuration?.minutes > 1) {
    statusUpdated = `${statusDuration.minutes} minutes`;
  } else if (statusDuration !== null) {
    statusUpdated = "about a minute";
  }

  return (
    <>
      <Sidepanel
        panelStyle={{ top: "3rem", right: "3rem", zIndex: 20 }}
        style={{ width: 300, height: 600, background: "#f9f9f9" }}
      >
        <div className="h-full grid grid-rows-[60px_auto_77px]">
          <div className="px-5 py-4 border-b border-gray-200">
            <div className="flex items-end justify-between">
              <SidepanelHeader
                onClose={(e) => {
                  e.preventDefault();
                  navigate(`/admin/active-routes/main`);
                }}
              >
                {data.route.name}
              </SidepanelHeader>
            </div>
          </div>
          <div className="px-4 py-5 md-py-16">
            <div className="pt-2 text-base flex items-center gap-x-3">
              <CrewIcon
                color={routeColor}
                lessSpace={true}
                enabled={crewStatus === "active"}
              />
              <span className="flex-auto">{foreman}</span>
            </div>
            <RouteDetailsProgressBar
              jobs={data.route.jobs}
              color={routeColor}
              onClick={(propertyId) => clickProperty(propertyId)}
            />
          </div>

          <div className="grid grid-cols-6 gap-2 border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="col-span-2">
              <button
                type="submit"
                onClick={handleDelete}
                className="inline-flex justify-center rounded-md bg-snow-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Discontinue
              </button>
            </div>
            {statusUpdated ? (
              <div className="col-span-4 justify-self-end place-content-end">
                <span className="text-xs text-gray-600 italic">
                  Updated {statusUpdated} ago
                </span>
              </div>
            ) : null}
          </div>
        </div>

        {/* Mapping items */}
        {data.operator.lastKnownLocation ? (
          <CrewPin
            position={data.operator.lastKnownLocation}
            status={crewStatus}
            color={routeColor}
          />
        ) : null}
        {data.route.start?.location ? (
          <StartEndMapMarker
            type="start"
            position={data.route.start.location}
          />
        ) : null}
        {data.route.end?.location ? (
          <StartEndMapMarker type="end" position={data.route.end.location} />
        ) : null}
        {/* TODO: if jobs loop, we may have problems by using property.id as a unique key */}
        {routeColor
          ? data.route.jobs.map((property) => {
              return (
                <>
                  <MapMarkerWithStatus
                    key={property._id}
                    color={routeColor}
                    status={property.status}
                    isActive={property._id === activePropertyId}
                    onClick={() => {
                      clickProperty(property._id);
                    }}
                    position={property.location}
                  />
                </>
              );
            })
          : null}
      </Sidepanel>
      <Outlet />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesRouteDetail);

const MapMarkerWithStatus = ({
  key,
  color,
  status,
  isActive,
  onClick,
  position,
}) => {
  const highlight = isActive || status === "current";
  const isComplete = status === "complete";
  const higherZIndex = isActive || status === "complete"; // per discussions w Daniel

  const bgColor = highlight
    ? colors[color].bgHover
    : isComplete
      ? `${colors[color].bg} is-new`
      : colors[color].bg;

  const highlighted = highlight ? "highlighted" : "";

  return (
    <AdvancedMarker
      key={key}
      zIndex={higherZIndex ? 20 : 10}
      position={position}
      onClick={onClick}
    >
      <div className={`map-marker-custom-pin ${bgColor} ${highlighted}`}>
        <div
          className={`map-marker-inner-circle ${bgColor} flex justify-center items-center`}
        >
          <div style={{ marginTop: "-3px" }}>
            {isComplete ? (
              <div
                className="size-4 flex justify-center items-center"
                style={{
                  width: "1.25rem",
                }}
              >
                <CompleteCheck />
              </div>
            ) : (
              <div className={`size-2 rounded-full bg-white`}></div>
            )}
          </div>
        </div>
      </div>
    </AdvancedMarker>
  );
};
