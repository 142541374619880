import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Services
import { RouteContext } from "../RouteContext";
import AddProperties from "./Add/Properties";

const UpdateRoutePage = ({ currentUser }) => {
  let { routeId } = useParams();
  const navigate = useNavigate();

  // pull jobs for this route of route context
  const { route, jobs, addJobs } = useContext(RouteContext);

  const closeRoute = () => {
    navigate("/admin/route/" + routeId, {
      preventScrollReset: true,
    });
  };
  const concatJobs = (newJobs) => {
    addJobs(newJobs);
    closeRoute();
  };

  return (
    <AddProperties
      onCancel={() => closeRoute()}
      companyId={currentUser.currentCompanyId}
      season={route.season}
      existingJobs={jobs}
      concatJobs={concatJobs}
    />
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(UpdateRoutePage);
export { UpdateRoutePage };
