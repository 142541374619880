import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// Components
import FilterBar from "../../../components/Filters/FilterBar";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Redux
import { updateUser } from "../../../store/reducers/currentUserSlice";

// Services
import DeactivateConfirmModal from "../../../components/Modals/DeactivateConfirmModal";
import axiosCompanies from "../../../services/axios/companies";
import axiosUsers from "../../../services/axios/users";
import flash from "../../../services/flash";
import { useQueryClient } from "@tanstack/react-query";
import Until from "../../../components/Until";
import CompanyLocations from "./UpdateCompanyPage/CompanyLocations";
import { useFeatureFlag, User } from "configcat-react";

const AdminUpdateCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentFilter, setCurrentFilter] = useState("");

  let params = useParams();
  let companyId = props.companyId ?? params.companyId;

  const [company, setCompany] = useState({});
  const [displayConfirmAddLicenseModal, setDisplayConfirmAddLicenseModal] =
    useState(false);
  const [
    displayConfirmRemoveLicenseModal,
    setDisplayConfirmRemoveLicenseModal,
  ] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(() => {
    setLoading(true);
    axiosCompanies.getCompanyById({ companyId }, (data) => {
      setCompany(data);
      setLoading(false);
    });
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [companyId, fetchData]);

  useEffect(() => {
    if (company?.temperature === "C") {
      setCurrentFilter("C");
    } else setCurrentFilter("F");
  }, [company]);

  const handleSaveCompany = useCallback(() => {
    setLoading(true);
    company.temperature = currentFilter;

    // Submit updated job to server
    axiosCompanies.updateCompanyById(
      {
        ...company,
        companyId: company._id,
        emailReportReplyTo: company.emailReportReplyTo.filter(
          (a) => a.trim() !== "",
        ),
      },
      (data) => {
        flash.success("Company settings updated!");
        setCompany({
          ...company,
          ...{
            locations: data.company.locations,
          },
        });
        // Update user to update company name
        axiosUsers.getUserById({ uid: props.currentUser.uid }, (userData) => {
          dispatch(updateUser(userData));
          setLoading(false);
        });
      },
      () => {
        flash.error("Had an error trying to save company settings");
        setLoading(false);
      },
    );
  }, [company, currentFilter, dispatch, props.currentUser.uid]);

  const handleAddCompanyLicense = useCallback(() => {
    if (!company) return;
    axiosCompanies.addCompanyLicense(
      { companyId: company._id, uid: props.currentUser.uid },
      () => {
        setDisplayConfirmAddLicenseModal(false);
        flash.success("Successfully Activated " + company.name);
        // Refresh data
        fetchData();
        queryClient.invalidateQueries({
          queryKey: [`user/${props.currentUser.uid}/companies`],
        });
        queryClient.invalidateQueries({
          queryKey: [`user/${props.currentUser.uid}/account`],
        });
      },
      (err) => {
        setDisplayConfirmAddLicenseModal(false);
        flash.error(
          err.data?.message ??
            "Unable to activate this company right now. Please contact support",
        );
        // If error caused by user unsubscribed or undersubscribed, display upgrade modal
        if (err.data.needsUpgrade) {
          alert(
            "Please upgrade your subscription before activating this company",
          );
          navigate("/admin/settings/billing");
        }
      },
    );
  }, [company, fetchData, navigate, queryClient, props.currentUser.uid]);

  const handleRemoveCompanyLicense = useCallback(async () => {
    if (!company) return;

    axiosCompanies.removeCompanyLicense(
      { companyId: company._id, uid: props.currentUser.uid },
      () => {
        setDisplayConfirmRemoveLicenseModal(false);
        flash.success("Successfully Deactivated " + company.name);
        // Refresh data
        fetchData();
        queryClient.invalidateQueries({
          queryKey: [`user/${props.currentUser.uid}/companies`],
        });
        queryClient.invalidateQueries({
          queryKey: [`user/${props.currentUser.uid}/account`],
        });
      },
      (err) => {
        setDisplayConfirmRemoveLicenseModal(false);
        alert(err.message ?? err.data.message);
        console.error("Error in handleLicenseCompany: ", err);
        alert(err);
      },
    );
  }, [company, fetchData, queryClient, props.currentUser.uid]);

  let buttons = [];
  if (company.isPaid) {
    buttons.push({
      // color: "red",
      label: "Deactivate",
      onClick: () => setDisplayConfirmRemoveLicenseModal(true),
    });
  } else {
    buttons.push({
      color: "green",
      label: "Activate",
      onClick: () => setDisplayConfirmAddLicenseModal(true),
    });
  }
  buttons.push({
    color: "green",
    label: "Save",
    onClick: handleSaveCompany,
  });

  let { value: mapFlag } = useFeatureFlag(
    "mappingWeb",
    false,
    new User(props.currentUser._id, null, null, {
      companyId: props.currentUser.currentCompanyId,
    }),
  );

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar buttons={buttons} title="Company Settings" />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            {company.isPaid ? null : (
              <div className="w-full text-left bg-snow-red text-full-white p-4 rounded-md mb-2">
                Note: This company is inactive. Employees will not be able to
                use this company in the field.
              </div>
            )}
            <LabeledTextboxInput
              label="Company Name"
              maxLength={50}
              placeholder="Name your company..."
              setValue={(val) => {
                setCompany({ ...company, name: val });
              }}
              value={company.name}
            />
            <LabeledTextboxInput
              disabled={true}
              label="My Role"
              value={props.currentUser.companies[company._id].role}
            />
            <LabeledTextboxInput
              disabled={true}
              label="Company Owner"
              value={company.owner.firstName + " " + company.owner.lastName}
            />
            <LabeledTextboxInput
              label="Company Name for Email Reports"
              description="This name will appear in the sender and subject line of email reports sent to clients."
              value={company.emailReportName ?? company.name}
              setValue={(val) => {
                setCompany({ ...company, emailReportName: val });
              }}
            />
            <LabeledTextboxInput
              label="Reply To Address for Email Reports"
              description="If a client replies to a report email, this is the email address that will get the response. Separate multiple values with a comma"
              value={company.emailReportReplyTo?.join(",") ?? ""}
              setValue={(val) => {
                val = val.trim();
                setCompany({
                  ...company,
                  emailReportReplyTo:
                    val.length > 0 ? val.split(",").map((s) => s.trim()) : [],
                });
              }}
              testId={"reply-to-email-reports"}
              placeholder="admin@example.com,demo@example.com"
            />
            <LabeledTextboxInput
              label="Hours Until Clock Out Reminder"
              description="Send a clock out reminder to foremen if their employees have been clocked in for longer than x hours.  Enter 0 to disable reminders."
              type="number"
              value={company.sendClockOutReminderAfterHours ?? 0}
              setValue={(val) => {
                setCompany({ ...company, sendClockOutReminderAfterHours: val });
              }}
            />

            {mapFlag ? (
              <CompanyLocations
                companyId={company._id}
                initialLocations={company.locations}
              />
            ) : null}

            <FilterBar
              currentFilter={currentFilter}
              filters={[
                { label: "Fahrenheit", value: "F" },
                { label: "Celsius", value: "C" },
              ]}
              setCurrentFilter={setCurrentFilter}
            />
            <Until until={new Date("2025-04-01T00:00:00")}>
              <p className="text-left w-full text-description-gray">
                Looking for join code? Now you can{" "}
                <Link className="underline" to="/admin/employees">
                  add employees directly
                </Link>{" "}
                without needing a join code.
              </p>
            </Until>
          </div>
        )}
      </div>
      {/* Add License To Company Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayConfirmAddLicenseModal}
        options={[
          {
            color: "green",
            label: "Activate",
            onClick: handleAddCompanyLicense,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmAddLicenseModal(false),
          },
        ]}
        subtitle="Are you sure you want to activate this company?"
        title="Activate Company"
      />

      {/* Remove License To Company Modal */}
      <DeactivateConfirmModal
        adjustForSidebar={true}
        open={displayConfirmRemoveLicenseModal}
        options={[
          {
            color: "red",
            label: "Deactivate",
            onClick: handleRemoveCompanyLicense,
          },
          {
            color: "blue",
            label: "I've changed my mind",
            onClick: () => setDisplayConfirmRemoveLicenseModal(false),
          },
        ]}
        title="Deactivate Company"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminUpdateCompanyPage);
export { AdminUpdateCompanyPage };
