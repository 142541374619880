import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// Components
import JobCard from "../../../components/Cards/JobCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";

// Services
import axiosJobs from "../../../services/axios/jobs";
import SearchBar from "../../../components/Filters/SearchBarWithFilters";
import flash from "../../../services/flash";
import InfiniteScroll from "react-infinite-scroll-component";

const seasonOptions = [
  { value: "snow", label: "Snow" },
  { value: "landscape", label: "Landscape" },
];
const AdminJobsPage = (props) => {
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [lastPage, setLastPage] = useState(false);
  const [nextPageToLoad, setNextPageToLoad] = useState(0);
  const [perPage] = useState(40);
  const [loading, setLoading] = useState(true);
  const loadPageOfJobs = useCallback(
    async (pageToLoad, filter, searchTerm, abortSignal) => {
      try {
        let resp = await axiosJobs.filterJobs(
          props.currentUser.currentCompanyId,
          filter,
          searchTerm,
          pageToLoad * perPage,
          perPage,
          abortSignal,
        );
        if (abortSignal == null || !abortSignal.aborted) {
          setNextPageToLoad(pageToLoad + 1); // do this before we await to reduce the risk of loading the same page twice
          if (resp.length > 0) {
            if (pageToLoad === 0) {
              setJobs([...resp]);
            } else {
              setJobs((r) => [...r, ...resp]);
            }
          }
          if (resp.length < perPage) {
            setLastPage(true);
          }
        }
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          flash.error("Unable to load Jobs - try refreshing.");
        }
      } finally {
        setLoading(false);
      }
    },
    [perPage, props.currentUser.currentCompanyId],
  );

  const loadCityOptions = useCallback(
    async (abortSignal) => {
      try {
        let options = await axiosJobs.loadCityOptions(
          props.currentUser.currentCompanyId,
          abortSignal,
        );
        setCityOptions(
          options.map((o) => {
            return { value: o, label: o };
          }),
        );
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          console.error("Unable to load Jobs - try refreshing.");
        }
      }
    },
    [props.currentUser.currentCompanyId],
  );
  useEffect(() => {
    const abort = new AbortController();
    setCityOptions([]);
    setCurrentFilter({});
    setJobs([]);
    loadPageOfJobs(0, null, searchTerm, abort.signal);
    loadCityOptions(abort.signal);
    return () => {
      abort.abort();
    };
  }, [props.currentUser, loadPageOfJobs, loadCityOptions]);

  const reloadDataWithNewCriteria = async (searchTerm, filters) => {
    setLastPage(false);
    setLoading(true);
    setJobs([]);
    loadPageOfJobs(0, filters, searchTerm);
  };

  const onSearch = (value) => {
    setSearchTerm(value);
    reloadDataWithNewCriteria(value, currentFilter);
  };

  const makeSetFilterFieldFnc = (field) => {
    return (value) => {
      let newFilters = {
        ...currentFilter,
        [field]: value?.value,
      };
      setCurrentFilter(newFilters);
      reloadDataWithNewCriteria(searchTerm, newFilters);
    };
  };
  const nextPage = async (abortSignal) => {
    if (lastPage) {
      return;
    }
    await loadPageOfJobs(
      nextPageToLoad,
      currentFilter,
      searchTerm,
      abortSignal,
    );
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar
        buttons={[
          {
            color: "blue",
            enabled: true,
            label: "Bulk Import",
            onClick: () => navigate("/admin/excel_import"),
          },
          {
            color: "blue",
            enabled: true,
            label: "Create Property",
            onClick: () => navigate("create"),
          },
        ]}
        title="Properties"
      />

      <div className="w-full px-10">
        <SearchBar onSearch={onSearch}>
          <Select
            onChange={makeSetFilterFieldFnc("season")}
            options={seasonOptions}
            placeholder="Filter by Season"
            isClearable={true}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#829399",
              }),
            }}
            value={
              currentFilter.season
                ? seasonOptions.find((o) => o.value == currentFilter.season)
                : null
            }
          />
          <Select
            onChange={makeSetFilterFieldFnc("city")}
            options={cityOptions}
            placeholder="Filter by City"
            isClearable={true}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#829399",
              }),
            }}
            value={
              currentFilter.city
                ? cityOptions.find((o) => o.value == currentFilter.city)
                : null
            }
          />
        </SearchBar>
      </div>

      {/* Render all Properties */}
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <div className="w-full">
            <InfiniteScroll
              dataLength={jobs.length}
              next={nextPage}
              hasMore={!lastPage}
              loader={<h4>Loading...</h4>}
              className="flex flex-1 flex-col w-full px-10 pt-5 items-center overflow-y-scroll"
              style={{ width: "100%" }}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  No more records matching your filters
                </p>
              }
            >
              {jobs.map((job, key) => (
                <JobCard
                  address={job.address}
                  key={key}
                  onClick={() => navigate("/admin/property/" + job._id)}
                  name={job.name}
                  routes={job.routes}
                />
              ))}
            </InfiniteScroll>
          </div>
          <p className="text-snow-light-grey">Showing {jobs.length} results</p>
          {lastPage ? null : (
            <button
              className="text-snow-light-grey"
              onClick={() => {
                nextPage();
              }}
            >
              Load More
            </button>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminJobsPage);
export { AdminJobsPage };
