import React from "react";

export const LocationSwitcher = ({ showLocations, toggleShowLocations }) => {
  const listBg = !showLocations ? "bg-gray-400" : "bg-gray-200";
  const locationsBg = showLocations ? "bg-gray-400" : "bg-gray-200";

  return (
    <div className="absolute top-0 right-4 text-xs">
      <button
        onClick={toggleShowLocations}
        className="grid grid-cols-2 text-white"
      >
        <div className={`${listBg} hover:bg-gray-500 p-1 rounded-bl`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
          </svg>
        </div>
        <div className={`${locationsBg} hover:bg-gray-500 p-1 rounded-br`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25,0.75 L17.25,0.75 M5.25,6 L17.25,6 M5.25,11.25 L17.25,11.25 M1.125,0.75 C1.125,0.957106781 0.957106781,1.125 0.75,1.125 C0.542893219,1.125 0.375,0.957106781 0.375,0.75 C0.375,0.542893219 0.542893219,0.375 0.75,0.375 C0.957106781,0.375 1.125,0.542893219 1.125,0.75 Z M1.125,11.25 C1.125,11.4571068 0.957106781,11.625 0.75,11.625 C0.542893219,11.625 0.375,11.4571068 0.375,11.25 C0.375,11.0428932 0.542893219,10.875 0.75,10.875 C0.957106781,10.875 1.125,11.0428932 1.125,11.25 Z"
              transform="translate(3 6)"
            />
          </svg>
        </div>
      </button>
    </div>
  );
};
