import { deepmergeCustom } from "deepmerge-ts";

const mergeOnlyValues = deepmergeCustom({
  mergeArrays: false,
  mergeMaps: false,
  mergeSets: false,
});

// for our season objects, we'll trust the frontend to merge
// anything that isn't an array. Arrays get messy though, so can't do that
const mergeNonArrays = deepmergeCustom({
  mergeArrays: false,
});

export const mergeJobUpdatePayload = (initialPayload, updatePayload) => {
  let finalPayload = mergeOnlyValues(initialPayload, updatePayload);

  // for these season keys, we'll default to a merge approach
  // but can't rely on arrays properly getting merged
  delete finalPayload.snow;
  delete finalPayload.landscape;

  if (
    updatePayload.landscape &&
    Object.keys(updatePayload.landscape).length > 0
  ) {
    finalPayload.landscape = mergeNonArrays(
      initialPayload.landscape,
      updatePayload.landscape,
    );
  } else {
    finalPayload.landscape = {};
  }
  if (updatePayload.snow && Object.keys(updatePayload.snow).length > 0) {
    finalPayload.snow = mergeNonArrays(initialPayload.snow, updatePayload.snow);
  } else {
    finalPayload.snow = {};
  }

  return finalPayload;
};
