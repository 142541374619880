import React from "react";

const SidepanelHeader = (props) => {
  return (
    <>
      <h2
        className="text-base font-semibold text-gray-900"
        id="slide-over-title"
      >
        {props.children}
      </h2>
      <div className="ml-3 flex h-7 items-center">
        {props.onClose ? (
          <button
            type="button"
            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
            onClick={props.onClose}
          >
            <span className="absolute -inset-2.5"></span>
            <span className="sr-only">Close panel</span>
            <svg
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        ) : null}
      </div>
    </>
  );
};

export default SidepanelHeader;
