import React from "react";

export const StartEndLabelAddress = ({
  type,
  address,
  isSelected,
  name,
  children,
}) => {
  const className = type === "start" ? "pt-0 pb-5" : "pt-3 pb-2";
  const label = type === "start" ? "Start" : "End";
  const needsSelection = isSelected ? "font-normal" : "text-red-600 font-bold";

  return (
    <div
      className={`grid grid-cols-[60px_auto] text-sm font-light ${className}`}
    >
      <div className={`${needsSelection}`}>
        <StartEndPin className="mr-1 -mt-0.5 inline-block" />
        {label}
      </div>
      {children ? (
        <div>{children}</div>
      ) : (
        <StartEndAddress name={name} address={address} />
      )}
    </div>
  );
};

export const StartEndAddress = ({ name, address }) => {
  return (
    <>
      <span className="text-xs">
        {name ? (
          <>
            <div className="text-sm font-normal mb-0.5">{name}</div>
            <span className="text-xs">{address}</span>
          </>
        ) : (
          <div className="mt-0.5">{address}</div>
        )}
      </span>
    </>
  );
};

const StartEndPin = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={`size-4 ${className ?? ""}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
      />
    </svg>
  );
};
