import React from "react";
import SubtleButton from "../../../../../../components/Buttons/SubtleButton";
import Sidepanel from "../../../../../../components/Maps/ActiveRoutes/ui/sidepanel";
import SidepanelFooter from "../../SidepanelFooter";
import RoutePath from "../../../../../../components/Maps/ActiveRoutes/RoutePath";
import { colors } from "../../../../../../components/Maps/ActiveRoutes/colors";
import { MapMarker, StartEndMapMarker } from "../../Map/Marker";
import { drivingDetails } from "../utils";
import { HeaderWithDetails } from "../Components/HeaderWithDetails";
import { useState } from "react";
import { JobStep } from "../../EditableJobItem";
import { StartEndLabelAddress } from "../Components/StartEndAddress";

const PreviewPane = ({
  isLoading,
  route,
  routeColor,
  routeLocations,
  jobs,
  drive,
  onClose,
}) => {
  let [highlightJobId, setHighlightJobId] = useState(null);

  const onClick = (jobId) => {
    setHighlightJobId(jobId);
  };

  const completedColor = colors[routeColor].hex;

  let drivingInfo = isLoading ? "" : drivingDetails(drive.distance, drive.time);

  return (
    <Sidepanel
      panelStyle={{ top: "1.5rem", right: "1.5rem", zIndex: 20 }}
      style={{ width: 300, height: 600, background: "#fff" }}
    >
      <div className="h-full grid grid-rows-[fit-content(140px)_auto_77px]">
        <HeaderWithDetails
          isLoading={isLoading}
          routeColor={routeColor}
          routeDetails={drivingInfo}
          routeName={route.name}
        />

        <div className="p-5 mt-1 overflow-y-scroll">
          <StartEndLabelAddress
            type="start"
            isSelected={true}
            address={routeLocations.start.address}
            name={routeLocations.start.name}
          />

          <ul role="list">
            {jobs.map((job, key) => {
              return (
                <li
                  key={key}
                  className="relative group py-0 min-h-8 w-full items-start"
                >
                  <JobStep
                    name={job.name}
                    address=""
                    highlight={highlightJobId === job.id}
                    showLocations={false}
                    number={key}
                    color={routeColor}
                    onClick={() => {
                      onClick(job.id);
                    }}
                    wide={false}
                  />
                </li>
              );
            })}
          </ul>
          <StartEndLabelAddress
            type="end"
            isSelected={true}
            address={routeLocations.end.address}
            name={routeLocations.end.name}
          />
        </div>

        {!isLoading ? (
          <RoutePath path={drive.encodedPath} color={completedColor} />
        ) : null}

        {routeLocations.start.location ? (
          <StartEndMapMarker
            type="start"
            position={routeLocations.start.location}
          />
        ) : null}
        {routeLocations.end.location ? (
          <StartEndMapMarker
            type="end"
            position={routeLocations.end.location}
          />
        ) : null}

        {jobs.map((job, index) => (
          <MapMarker
            key={job.id}
            color={routeColor}
            number={index + 1}
            highlight={job.id === highlightJobId}
            onClick={() => {
              setHighlightJobId(job.id);
            }}
            position={{ lat: job.location.lat, lng: job.location.lng }}
          />
        ))}

        <SidepanelFooter>
          <SubtleButton
            onClick={onClose}
            type="submit"
            testId="close-button"
            buttonRole="secondary"
            className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
          >
            Exit Preview
          </SubtleButton>
        </SidepanelFooter>
      </div>
    </Sidepanel>
  );
};

export default PreviewPane;
