// much of what is in here comes from https://github.com/visgl/react-google-maps/blob/main/examples/drawing/src/types.ts
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import RoutePath from "./RoutePath";
import { useMapPathZoom } from "./use-map-zoom";
import Sidepanel from "./ui/sidepanel";
import SidepanelHeader from "./ui/sidepanel-header";
import { calculateCrewStatus, CrewIcon, CrewPin } from "./ui/crew";
import { colors } from "./colors";

const ActiveRouteItem = ({ data }) => {
  const navigate = useNavigate();
  let { routeId } = useParams();

  let foreman = data.operator?.firstName + " " + data.operator?.lastName;
  const routeColor = data.route.color ?? "emerald";

  let bgColor = colors[routeColor].bgLight;
  let routePathColor = colors[routeColor].hex;
  let textColor = colors[routeColor].text;

  let makeRouteActive = routeId === undefined || routeId === data._id;

  let jobsRemaining = data.route.jobs.reduce((acc, job) => {
    if (job.status === "upcoming") return acc;

    acc++;
    return acc;
  }, 0);

  let routeStatus = (
    <span
      className={`mr-4 inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ring-red-600/10`}
    >
      {jobsRemaining} / {data.route.jobs.length}
    </span>
  );

  let routeClick = (e) => {
    e.preventDefault();
    navigate(`/admin/active-routes/main/${data._id}`);
  };
  let mapItemClick = (path) => {
    routeClick(path.domEvent);
  };
  const [crewStatus] = calculateCrewStatus(
    data.operator?.lastKnownLocation?.createdAt,
  );

  return (
    <li className="p-4 hover:bg-gray-50">
      <a
        href={`/admin/active-routes/main/${data._id}`}
        onClick={routeClick}
        className="hover:cursor-pointer"
      >
        <div className="flex items-center gap-x-3">
          <CrewIcon
            lessSpace={true}
            color={routeColor}
            enabled={crewStatus === "active"}
          />
          <h3
            className={`flex-auto truncate text-sm/6 font-semibold ${textColor}`}
          >
            {foreman}
          </h3>
          <time
            dateTime="2023-01-23T11:00"
            className="flex-none text-xs text-gray-700"
          >
            {routeStatus}
          </time>
        </div>
        <p className={`mt-1 ml-8 truncate text-sm ${textColor}`}>
          {data.route.name}
        </p>
      </a>
      {data.operator.lastKnownLocation ? (
        <CrewPin
          position={data.operator.lastKnownLocation}
          status={crewStatus}
          color={routeColor}
        />
      ) : null}
      {data.route.drivingPath ? (
        <RoutePath
          pathClick={mapItemClick}
          path={data.route.drivingPath}
          color={routePathColor}
          isActive={makeRouteActive}
        />
      ) : null}
    </li>
  );
};

const ActiveRoutesMap = ({ activeRoutes }) => {
  let routePaths = [];
  activeRoutes.map((route) => {
    routePaths.push(route.drivingPath);
  });

  const snowRoutes = activeRoutes.filter(
    (data) => data.route.season === "snow",
  );
  const landscapeRoutes = activeRoutes.filter(
    (data) => data.route.season === "landscape",
  );
  // we're unlikely to hit this very often, but it could be quite confusing
  // if we do. let's make it easier to understand in the UI ...
  const showingMultipleRouteTypes =
    snowRoutes.length > 0 && landscapeRoutes.length > 0;

  useMapPathZoom(routePaths);

  return (
    <Sidepanel>
      <div className="p-6">
        <div className="flex items-start justify-between">
          <SidepanelHeader>Active Crews</SidepanelHeader>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          See active crew and route details
        </p>
      </div>
      <div className="md-py-16">
        {snowRoutes.length > 0 ? (
          <ActiveRoutesList
            activeRoutes={snowRoutes}
            showHeader={showingMultipleRouteTypes}
            header="Snow"
          />
        ) : null}
        {landscapeRoutes.length > 0 ? (
          <ActiveRoutesList
            activeRoutes={landscapeRoutes}
            showHeader={showingMultipleRouteTypes}
            header="Landscape"
          />
        ) : null}
      </div>
    </Sidepanel>
  );
};

const ActiveRoutesList = ({ activeRoutes, showHeader, header }) => (
  <>
    {showHeader ? <h3 className="ml-3 text-gray-600">{header}</h3> : null}
    <ul
      role="list"
      className="flex-1 divide-y divide-gray-100 border-t border-gray-300"
    >
      {activeRoutes.length == 0 ? (
        <li className="p-6">
          <h3 className="flex-auto truncate text-sm/6 text-gray-900">
            No crews are active right now
          </h3>
        </li>
      ) : (
        <>
          {activeRoutes.map((route, key) => (
            <ActiveRouteItem key={key} data={route} />
          ))}
        </>
      )}
    </ul>
  </>
);

export default ActiveRoutesMap;
