// much of what is in here comes from https://github.com/visgl/react-google-maps/blob/main/examples/drawing/src/types.ts
import React from "react";
import {
  APIProvider,
  ControlPosition,
  Map,
  useApiIsLoaded,
} from "@vis.gl/react-google-maps";
import { API_KEY } from "../../../config/maps";

const ACTIVE_ROUTE_MAP_ID = "8cbff7fc95a543f2";

const MapLoader = (props) => {
  const apiIsLoaded = useApiIsLoaded();

  return apiIsLoaded ? props.children : null;
};

const ActiveRoutesBaseLayer = (props) => {
  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        mapId={ACTIVE_ROUTE_MAP_ID}
        style={{ width: "100%", height: "100%" }}
        defaultCenter={{ lat: 42.503123, lng: -83.176939 }}
        defaultZoom={13}
        disableDefaultUI={true}
        zoomControl={true}
        zoomControlOptions={{
          position: ControlPosition.INLINE_START_BLOCK_END,
        }}
        gestureHandling={"greedy"}
      />

      <MapLoader>{props.children}</MapLoader>
    </APIProvider>
  );
};

export default ActiveRoutesBaseLayer;
