import React from "react";
import { AddJobIcon } from "./EditableJobItem";
import { formatAddress } from "./Routes/utils";
import { colors } from "../../../../components/Maps/ActiveRoutes/colors";

function MapJobCard(props) {
  const fullAddress = formatAddress(props.address);

  let isSelected = props.cardSequence;

  return (
    <>
      <div className="grid grid-cols-[20px_auto] gap-x-3">
        <AddJobIcon
          isSelected={isSelected}
          previouslySelected={props.previouslySelected}
        />
        <div className="ml-1">
          <span className="flex min-w-0 flex-col">
            <span className="text-sm font-semibold text-gray-900">
              {props.name || "No Property Name Given"}
              {props.cardSequence ? (
                <span> (#{props.cardSequence})</span>
              ) : (
                <span>{props.previouslySelected ? <> (added)</> : null}</span>
              )}
            </span>
          </span>
          <p className="mt-0 truncate text-sm text-gray-500">{fullAddress}</p>
          <RoutesBubbles routes={props.routes} />
        </div>
      </div>
    </>
  );
}

export default MapJobCard;

export const RoutesBubbles = ({ routes }) => {
  return (
    <>
      {routes && routes.length > 0 ? (
        <div className="mt-1">
          {routes.map((route, idx) => {
            let colorOptions = colors[route.color];
            return (
              <>
                <span
                  key={idx}
                  className={`rounded-md inline-block mr-1 mb-1 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-red-600/10 ${colorOptions.text} ${colorOptions.bgLight}`}
                >
                  {route.name}
                </span>
              </>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
