import React from "react";
import CheckBox from "../CheckBoxes/CheckBox";
import { REPORT_OPTIONS } from "@snowscape/snow-lib";

const ReportSettingsField = (props) => {
  return (
    <div className="w-full mb-5">
      <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
        {props.season === "snow" ? "Snow " : "Landscape "}Report Includes
      </div>

      <div className="text-sm font-light mb-1 self-start text-snow-primary">
        {props.subtitle ||
          "Select what information you want included in your report emails"}
      </div>
      {props.enableSelectAll ? (
        <div
          className="text-sm font-light underline self-start text-snow-primary hover:opacity-60 active:opacity-40 w-fit cursor-pointer mt-1"
          onClick={() => {
            const updatedReportSettings = {
              ...props.reportSettings,
            };

            for (const key in REPORT_OPTIONS) {
              updatedReportSettings[key] = true;
            }

            props.setReportSettings(updatedReportSettings);
          }}
        >
          (Select All)
        </div>
      ) : null}

      {Object.keys(REPORT_OPTIONS).map((key) => (
        <CheckBox
          key={key}
          checked={props.reportSettings?.[key]}
          label={REPORT_OPTIONS[key].label}
          labelMargin={true}
          onClick={() => {
            props.setReportSettings({
              ...props.reportSettings,
              [key]: !(
                props.reportSettings?.[key] ?? REPORT_OPTIONS[key].default
              ),
            });
          }}
          season={props.season}
        />
      ))}
    </div>
  );
};

export default ReportSettingsField;
