import React from "react";

// Components
import SubtleButton from "../../../../../components/Buttons/SubtleButton";
import Sidepanel from "../../../../../components/Maps/ActiveRoutes/ui/sidepanel";
import { EditableJobsForRoute } from "../EditableJobItem";
import SidepanelFooter from "../SidepanelFooter";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { MapMarker, StartEndMapMarker } from "../Map/Marker";
import { useContext } from "react";
import { RouteContext } from "../RouteContext";
import { useState } from "react";
import { HeaderWithDetails } from "./Components/HeaderWithDetails";
import { drivingDetails } from "./utils";
import RoutePath from "../../../../../components/Maps/ActiveRoutes/RoutePath";
import { LocationSwitcher } from "./Index/LocationSwitcher";
import { useMapJobsZoom } from "../../../../../components/Maps/ActiveRoutes/use-map-zoom";
import { colors } from "../../../../../components/Maps/ActiveRoutes/colors";
import { LocationChooser } from "./Components/LocationChooser";

const OUTDATED_ROUTE_COLOR = "#555";

const IndexRoute = () => {
  let navigate = useNavigate();
  let { routeId } = useParams();

  let [highlightJobId, setHighlightJobId] = useState(null);
  let [showLocations, setShowLocations] = useState(false);

  // pull jobs for this route of route context
  const {
    companyLocations,
    route,
    routeColor,
    jobs,
    routeChanged,
    routeLocations,
    setJobs,
    setRouteLocation,
    saveNewLocation,
  } = useContext(RouteContext);

  useMapJobsZoom(jobs);

  let addRouteClick = (e) => {
    e.preventDefault();
    navigate(`/admin/route/${routeId}/main/add-jobs`, {
      preventScrollReset: true,
    });
  };
  let previewClick = (e) => {
    e.preventDefault();
    navigate(`/admin/route/${routeId}/preview`, {
      preventScrollReset: true,
    });
  };
  let optimizeJobsClick = (e) => {
    e.preventDefault();
    navigate(`/admin/route/${routeId}/optimize`, {
      preventScrollReset: true,
    });
  };
  const updateOrder = (reorderedJobs, movedJobId) => {
    setJobs(reorderedJobs);
    setHighlightJobId(movedJobId);
  };
  const onDelete = (jobId) => {
    setJobs(jobs.filter((job) => job.id !== jobId));
  };
  const onClick = (jobId) => {
    setHighlightJobId(highlightJobId === jobId ? null : jobId);
  };

  const addLocation = (type, place) => {
    setRouteLocation(type, {
      _id: "",
      address: place.formatted_address,
      location: place.geometry.location.toJSON(),
      name: place.name,
    });
  };

  let drivingInfo =
    routeChanged ||
    !route?.drivingDistanceFormatted ||
    !route?.drivingDurationSeconds
      ? ""
      : drivingDetails(
          route.drivingDistanceFormatted,
          route.drivingDurationSeconds,
        );

  const haveJobs = jobs.length > 0;
  const routePathColor = colors[routeColor].hex;

  const disableBtns =
    !haveJobs || !routeLocations.start.location || !routeLocations.end.location;

  return (
    <>
      <Sidepanel>
        <div className="h-full grid grid-rows-[fit-content(140px)_auto_77px]">
          <HeaderWithDetails
            isLoading={false}
            routeChanged={routeChanged}
            routeColor={routeColor}
            routeDetails={drivingInfo}
            routeName={route.name}
          />

          <div className="px-5 py-4 md-py-16 relative overflow-y-scroll">
            {/* We pass in a method so we can further configure our address chooser */}
            <EditableJobsForRoute
              locationChooser={(type) => (
                <LocationChooser
                  locations={companyLocations}
                  routeLocations={routeLocations}
                  onAddLocation={addLocation}
                  onChooseLocation={setRouteLocation}
                  onSaveLocation={saveNewLocation}
                  type={type}
                />
              )}
              jobs={jobs}
              highlightJobId={highlightJobId}
              color={routeColor}
              routeLocations={routeLocations}
              showLocations={showLocations}
              updateOrder={updateOrder}
              onAdd={addRouteClick}
              onClick={onClick}
              onDelete={onDelete}
            />
            {haveJobs ? (
              <div className="mt-0 pt-4 text-left">
                <button
                  onClick={addRouteClick}
                  type="submit"
                  className="text-xs px-2 py-1 rounded font-bold border border-solid border-gray-200 bg-white hover:bg-gray-50 text-gray-500"
                  data-testid="add-properties-button"
                >
                  + Add a Property
                </button>
              </div>
            ) : null}
            <LocationSwitcher
              onAdd={addRouteClick}
              showLocations={showLocations}
              toggleShowLocations={() => setShowLocations(!showLocations)}
            />
          </div>

          <RoutePath
            path={route.drivingPath}
            color={!routeChanged ? routePathColor : OUTDATED_ROUTE_COLOR}
          />

          {routeLocations.start.location ? (
            <StartEndMapMarker
              type="start"
              position={routeLocations.start.location}
            />
          ) : null}
          {routeLocations.end.location ? (
            <StartEndMapMarker
              type="end"
              position={routeLocations.end.location}
            />
          ) : null}

          {jobs.map((job, index) => {
            if (!job.location.lat || job.location.lng) return null;

            return (
              <MapMarker
                key={job.id}
                color={routeColor}
                number={index + 1}
                highlight={job.id === highlightJobId}
                isNew={job.isNew ?? false}
                onClick={() => {
                  setHighlightJobId(job.id);
                }}
                position={{ lat: job.location.lat, lng: job.location.lng }}
              />
            );
          })}

          <SidepanelFooter>
            <SubtleButton
              onClick={optimizeJobsClick}
              type="submit"
              testId="optimize-properties"
              buttonRole="primary"
              disabled={disableBtns}
              className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-4"
              >
                <path
                  fillRule="evenodd"
                  d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                  clipRule="evenodd"
                />
              </svg>
              &nbsp;Optimize ...
            </SubtleButton>

            <SubtleButton
              onClick={previewClick}
              type="submit"
              testId="add-properties-button"
              buttonRole="secondary"
              disabled={disableBtns}
              className="inline-flex justify-center rounded-md px-3 py-2 text-sm"
            >
              Preview
            </SubtleButton>
          </SidepanelFooter>
        </div>
      </Sidepanel>
      <Outlet />
    </>
  );
};

export default IndexRoute;
