/* global google */

import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

const getLatLngsFromEncodedPaths = (geometryLibrary, paths) => {
  var markers = [];
  paths
    .filter((path) => path != null)
    .forEach((encodedPath) => {
      const path = geometryLibrary.encoding.decodePath(encodedPath);

      path.forEach((point) => {
        markers.push({
          lat: point.lat(),
          lng: point.lng(),
        });
      });
    });
  return markers;
};

const getLatLngsFromJobs = (jobs) => {
  var markers = [];
  jobs
    .filter((job) => job.location?.lat != null)
    .forEach((job) => markers.push(job.location));
  return markers;
};

export function useMapJobsZoom(jobs = []) {
  let map = useMap();
  const geometryLibrary = useMapsLibrary("geometry");

  useEffect(() => {
    if (!map || jobs.length === 0 || !geometryLibrary) return;
    // single time effect, we want to zoom in on our jobs to make it easier to see
    var bounds = new google.maps.LatLngBounds();

    var _markers = getLatLngsFromJobs(jobs);
    _markers.forEach((marker) => {
      bounds.extend(marker);
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, 100);
    }
  }, [jobs, geometryLibrary, map]);
}

export function useMapPathZoom(paths = [], resetPaths = []) {
  let map = useMap();
  const geometryLibrary = useMapsLibrary("geometry");

  useEffect(() => {
    if (!map || paths.length === 0 || !geometryLibrary) return;
    // single time effect, we want to zoom in on our jobs to make it easier to see
    var bounds = new google.maps.LatLngBounds();

    var markers = getLatLngsFromEncodedPaths(geometryLibrary, paths);
    markers.forEach((marker) => {
      bounds.extend(marker);
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }

    return () => {
      if (resetPaths.length === 0 || !geometryLibrary) return;
      var bounds = new google.maps.LatLngBounds();

      var markers = getLatLngsFromEncodedPaths(geometryLibrary, resetPaths);
      markers.forEach((marker) => {
        bounds.extend(marker);
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    };
  }, [paths, resetPaths, geometryLibrary, map]);
}
