import React from "react";

import Github from "@uiw/react-color-github";
import { useState } from "react";
import { colors } from "../Maps/ActiveRoutes/colors";

const colorSwatches = Object.keys(colors).map((color) => colors[color].hex);

function ColorPicker({ label, value, setValue }) {
  const [open, setOpen] = useState(false);

  let internalColor = colors[value].hex;

  const internalOnChange = (color) => {
    // match against our colors object so we know the name for internal purposes
    let ourColor = Object.entries(colors).find(
      (value) => value[1].hex == color.hex,
    );
    setValue(ourColor[0]);
    setOpen(false);
  };

  return (
    <div className="w-full text-snow-primary mb-5">
      <div className="text-sm font-bold mb-0.5">{label}</div>
      <div className="grid grid-cols-[50px_auto] my-2 mb-4">
        <div
          className="rounded"
          onClick={() => setOpen(!open)}
          style={{ width: 40, height: 40, backgroundColor: internalColor }}
        />
        {open ? (
          <div className="">
            <Github
              placement="L"
              colors={colorSwatches}
              color={internalColor}
              style={{
                "--github-background-color": "#fff",
              }}
              onChange={internalOnChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ColorPicker;
