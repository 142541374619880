import React, { useEffect, useState } from "react";
import axiosJobs from "../../../../services/axios/jobs";
import StandardButton from "../../../../components/Buttons/StandardButton";
import JobCard from "../../../../components/Cards/JobCard";
import LoadingIcon from "../../../../components/Loaders/LoadingIcon";

const AddPropertyWrapper = (props) => {
  // const [currentFilter, setCurrentFilter] = useState('all');
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  useEffect(() => {
    if (!props.active) return;
    setJobsLoading(true);
    axiosJobs.getAllJobsForSeason(
      { companyId: props.companyId, season: props.season },
      (data) => {
        setJobs(data);
        setJobsLoading(false);
      },
    );
  }, [props.active, props.companyId, props.season]);

  const handleAddJobs = () => {
    const jobsToAdd = selectedJobIds.map((j) =>
      jobs.find((element) => element._id === j),
    );

    props.concatJobs(jobsToAdd);
    props.setShowProperty(false);
  };

  const handleCardPressed = (index) => {
    let updatedJobs = [...jobs];
    const updatedJob = { ...updatedJobs[index] };
    let updatedSelectedJobIds = [...selectedJobIds];

    // If job not already selected, select
    if (!updatedJob.cardSequence) {
      updatedJobs[index].cardSequence = selectedJobIds.length + 1;
      updatedSelectedJobIds.push(updatedJob._id);
    } else {
      updatedSelectedJobIds = updatedSelectedJobIds.filter(
        (id) => id !== updatedJob._id,
      );
      updatedJobs = updatedJobs.map((job, i) => {
        if (index === i) {
          // selected job
          return { ...job, cardSequence: null };
        } else if (job.cardSequence > updatedJob.cardSequence) {
          // greater than selected job
          return { ...job, cardSequence: job.cardSequence - 1 };
        } else {
          // less than selected job
          return { ...job };
        }
      });
    }

    // Update State
    setJobs(updatedJobs);
    setSelectedJobIds(updatedSelectedJobIds);
  };

  return (
    <div
      className="flex flex-1 flex-col w-full p-4 lg:p-10 items-center bg-snow-blue-3 overflow-y-scroll"
      data-testid="add-jobs-dialog"
    >
      {/* <FilterBar
                currentFilter={currentFilter}
                filters={[
                    { label: 'All', value: 'all' },
                    { label: 'Landscape', value: 'landscape' },
                    { label: 'Snow', value: 'snow' },
                ]}
                lightMode={true}
                setCurrentFilter={setCurrentFilter}
            /> */}
      <div className="flex flex-col lg:flex-row w-full justify-between">
        <div className="text-2xl font-bold text-snow-white order-2 lg:order-1">
          Select properties to add
        </div>
        <div className="flex gap-4 justify-end order-1 lg:order-2">
          <StandardButton
            color="red"
            label="Cancel"
            onClick={() => (props.onCancel ? props.onCancel() : null)}
            testId="cancel-add-button"
          />
          <StandardButton
            color="green"
            label="Add"
            onClick={handleAddJobs}
            testId="complete-add-properties-button"
          />
        </div>
      </div>
      <p className="text-full-white text-sm my-2">
        Properties that are already part of this route are grayed out, but can
        still be added if you want to service a property more than once during a
        route.
      </p>

      {jobsLoading ? (
        <LoadingIcon color="white" />
      ) : (
        <div className="w-full my-3">
          {jobs.map((job, key) => (
            <JobCard
              address={job.address}
              cardSequence={job.cardSequence}
              key={key}
              onClick={() => {
                handleCardPressed(key);
              }}
              name={job.name}
              routes={job.routes}
              className={`${props.existingJobs.find((ej) => ej._id == job._id) !== undefined ? "opacity-70" : ""} cursor-pointer`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AddPropertyWrapper;
