export const colors = {
  emerald: {
    border: "border-emerald-600",
    bgLight: "bg-emerald-50",
    bg: "bg-emerald-600",
    bgHover: "bg-emerald-800",
    text: "text-emerald-600",
    textHover: "text-emerald-800",
    hex: "#059669",
  },
  purple: {
    border: "border-violet-600",
    bgLight: "bg-violet-50",
    bg: "bg-violet-600",
    bgHover: "bg-violet-800",
    text: "text-violet-600",
    textHover: "text-violet-800",
    hex: "#7c3aed",
  },
  green: {
    border: "border-green-600",
    bgLight: "bg-green-50",
    bg: "bg-green-600",
    bgHover: "bg-green-800",
    text: "text-green-600",
    textHover: "text-green-800",
    hex: "#16a34a",
  },
  orange: {
    // bg-orange-x wasn't working, so switched to amber for now
    border: "border-amber-600",
    bgLight: "bg-amber-50",
    bg: "bg-amber-600",
    bgHover: "bg-amber-800",
    text: "text-amber-600",
    textHover: "text-amber-800",
    hex: "#d97706",
  },
  blue: {
    border: "border-sky-600",
    bgLight: "bg-sky-50",
    bg: "bg-sky-600",
    bgHover: "bg-sky-800",
    text: "text-sky-600",
    textHover: "text-sky-800",
    hex: "#0284c7",
  },
  red: {
    border: "border-rose-600",
    bgLight: "bg-rose-50",
    bg: "bg-rose-600",
    bgHover: "bg-rose-800",
    text: "text-rose-600",
    textHover: "text-rose-800",
    hex: "#e11d48",
  },
  gray: {
    border: "border-slate-600",
    bgLight: "bg-slate-50",
    bg: "bg-slate-600",
    bgHover: "bg-slate-800",
    text: "text-slate-600",
    textHover: "text-slate-800",
    hex: "#475569",
  },
};
